<template>
    <ion-page>
        <ion-header>
            <ion-toolbar v-if-mobile>
                <BackNavButton></BackNavButton>
                <ion-title>{{$t('RoadBooks')}}</ion-title>
            </ion-toolbar>
            <ion-toolbar v-if-desktop>
                <ion-button slot="start"  expand="block" fill="outline" shape="round" size="large">
                    <ion-icon slot="start" :icon="map"></ion-icon>
                    {{$t('Map')}}
                </ion-button>
                <ion-button shape="rounded" size="large" slot="start" @click="sitchToSelect" class="ion-margin-start ion-margin-end" :fill="selectionEnabled?'outline':'clear'" color="light">
                    <ion-icon slot="start" :icon="selectionEnabled ? close:albumsOutline"></ion-icon>
                    {{$t('Select')}}
                </ion-button>
                <ion-button fill="clear" shape="round" @click="toggleCardSize">
                    Change size
                </ion-button>
                <ion-button v-if="!selectionEnabled" shape="rounded" size="large" slot="end" class="ion-margin-end" fill="clear" color="light">
                    <ion-icon slot="start" :icon="arrowDownCircleOutline"></ion-icon>
                    {{$t('Import')}}
                </ion-button>
                <ion-button v-if="!selectionEnabled" v-show="fetchedRoadbooks.length" slot="end" @click="roadbookModal.open()" shape="round" size="large" class="bold">
                    <ion-icon ref="createButton" slot="start" :icon="addCircleOutline"></ion-icon>
                    {{$t('Create')}}
                </ion-button>
                <ion-button v-if="selectionEnabled" :disabled="!selectedRoadbooks.length" slot="end" @click="tagSelected" size="large" class="bold ion-margin-end" fill="outline" >
                    <ion-icon slot="start" :icon="pricetagOutline"></ion-icon>
                    {{$t('Category')}}
                </ion-button>
                <ion-button v-if="selectionEnabled" :disabled="!selectedRoadbooks.length" slot="end" @click="archiveSelected" size="large" class="bold ion-margin-end" >
                    <ion-icon slot="start" :icon="archiveOutline"></ion-icon>
                    {{$t('Archive')}}
                </ion-button>
                <ion-button v-if="selectionEnabled" :disabled="!selectedRoadbooks.length" slot="end" @click="deleteSelected" size="large" class="bold ion-margin-end" color="danger">
                    <ion-icon slot="start" :icon="trashBinOutline"></ion-icon>
                    {{$t('Delete')}}
                </ion-button>
            </ion-toolbar>
        </ion-header>
        <ion-content>
            <div v-if="fetchedRoadbooks.length">
                <ion-toolbar>
                    <ion-item>
                        <RoadbookFilter ref="roadbookFilter" :roadbooks="fetchedRoadbooks" v-model:filtered-roadbooks="roadbooks"
                            btn-slot="start" btn-class="ion-margin-end"></RoadbookFilter>
                        <ion-searchbar :placeholder="$t('Search')" color="light"></ion-searchbar>
                    </ion-item>
                </ion-toolbar>
                <RoadbooksTree></RoadbooksTree>
                <RoadbooksGallery></RoadbooksGallery>
            </div>
            <div v-else class="div-center text-center">
                <div>
                    <ion-text>
                        <h4>{{ $t("Your list is empty")}}</h4>
                    </ion-text>
                </div>
                <ion-button @click="roadbookModal.open()" shape="round" size="large" class="bold">
                    {{ $t("Create a Roadbook")}}
                </ion-button>
            </div>
            <div v-if="fetchedRoadbooks.length && !roadbooks.length" class="div-center text-center">
                <div>
                    <ion-text>
                        <h4>{{ $t("No result")}}</h4>
                    </ion-text>
                </div>
            </div>
        </ion-content>

        <CreateRoadbookModal ref="roadbookModal" @onCreated="roadbookCreated" showTitle></CreateRoadbookModal>
        <RoadbookInfoModal ref="roadbookInfoModal"></RoadbookInfoModal>
    </ion-page>
</template>

<script setup lang="ts">
    import { roadbookAPI } from '@/libs/backend';
    import { addCircleOutline, albumsOutline, archiveOutline, arrowDownCircleOutline, close, map, pricetagOutline, trashBinOutline } from 'ionicons/icons';
    import { ref, onMounted, onUpdated, inject } from 'vue';
    import { refreshData, confirmAlert } from '@/libs/userExperience'
    import RoadbooksGallery from '@/components/RoadbooksGallery.vue';
    import RoadbooksTree from '@/components/RoadbooksTree.vue';
    import CreateRoadbookModal from '@/components/CreateRoadbookModal.vue';
    import RoadbookFilter from '@/components/RoadbookFilter.vue';
    import RoadbookInfoModal from '@/components/RoadbookInfoModal.vue';
    import BackNavButton from '@/components/BackNavButton.vue';
    import { useI18n } from "vue-i18n";
    import { useRouter } from 'vue-router';
    import { createAnimation, loadingController, alertController } from '@ionic/vue';
    import { confirmDeletion } from '@/libs/userExperience'

    // Variables
    const isDesktop=inject('isDesktop')
    const isMobile=inject('isMobile')
    const i18n = useI18n();
    const router = useRouter();
    
    const roadbooks:any = ref([])
    const fetchedRoadbooks:any = ref([])
    const selectionEnabled = ref(false)
    const revealCardActions = ref(null)
    const cardSize = ref(2.4)
    
    var copied = false;
    var selectedRoadbooks:any = ref([]);

    // HTML Element refs
    const roadbookModal = ref()
    const createButton = ref()
    const roadbookFilter = ref()
    const roadbookInfoModal = ref()

    onMounted(async ()=>{
        fetchRoadbooks();

        // Create button animation
        createAnimation()
          .addElement(createButton.value.$el)
          .fill('none')
          .duration(5000)
          .iterations(Infinity)
          .keyframes([
            { offset: 0, transform: 'scale(1)', opacity: '1' },
            { offset: 0.1, transform: 'scale(1.2)', opacity: '0.3' },
            { offset: 0.2, transform: 'scale(1)', opacity: '1' },
            { offset: 1, transform: 'scale(1)', opacity: '1' },
          ]).play();
    });

    onUpdated(async ()=>{
        refreshData(fetchRoadbooks)
    });

    function toggleCardSize(){
        console.log(cardSize.value)
        cardSize.value = 4
        if(cardSize.value == 2.4) cardSize.value = 3
        if(cardSize.value == 3) cardSize.value = 4
        if(cardSize.value == 4) cardSize.value = 2.4
    }
  
    function revealCard(roadbookId:any){
        if(isMobile && revealCardActions.value == roadbookId) revealCardActions.value = null
        else revealCardActions.value = roadbookId;
    }

    async function fetchRoadbooks(){
        selectedRoadbooks.value = []; // reset selected roadbooks
        selectionEnabled.value = false;
        roadbooks.value = await roadbookAPI.all();
        fetchedRoadbooks.value = roadbooks.value;
    }

    async function roadbookCreated(roadbook:any){
        let edit = true;
        if(copied){
            fetchRoadbooks(); // display new one anyway
            edit = await confirmAlert({
                header: i18n.t('Edit this copy ?'),
                mode: 'ios',
                buttons: [
                    { text: i18n.t('No'), role: 'cancel' },
                    { text: i18n.t('Yes'), role: 'confirm' },
                ]
            })
        }

        copied = false;
        
        if(edit){
            router.push({path: `/roadbooks/${roadbook.id}/edit/${roadbook.type}`})
        }
    }

    async function rename(roadbook:any){
        const alert = await alertController.create({
            header: i18n.t('Edit Title'),
            buttons: [ 
                {role:"cancel", text: i18n.t('Discard')},
                {role:"confirm", text: i18n.t('OK')}
            ],
            inputs: [
                {
                    placeholder: i18n.t('Add a title here'),
                    value: roadbook.title,
                    id: 'title-rename',
                },
            ],
        });
        await alert.present();
        // focus field on alert show
        document.getElementById('title-rename')?.focus();

        const {role, data} = await alert.onDidDismiss();
        if(role == 'confirm'){ 
            // update roadbook title
            roadbook.title = data.values[0];
            roadbookAPI.update(roadbook.id, roadbook);
        }
    }

    function shareRoadbook(roadbook:any){
        alert('TODO SHARE')
    }

    function uploadRoadbook(roadbook:any){
        alert('TODO UPLOAD')
    }

    function archiveRoadbook(roadbook:any){
        alert('TODO ARCHIVE')
    }

    async function deleteRoadbook(roadbookID:string){
        if(await confirmDeletion()){
            roadbookAPI.delete(roadbookID).then(()=> fetchRoadbooks())
        }
    }

    function copyRoadbook(roadbook:any){
        let data = JSON.parse(JSON.stringify(roadbook)); // copy object
        data.title = i18n.t('Copy of') + " " + data.title
        if(data.id) delete data.id
        copied = true;
        roadbookModal.value.openCopy(data)
    }

    function sitchToSelect(){
        selectionEnabled.value = !selectionEnabled.value;
        selectedRoadbooks.value = [];
    }

    function select(e:Event, roadbook:any){
        if(selectionEnabled.value == true){
            e.preventDefault()
            e.stopPropagation()
            let index = selectedRoadbooks.value.indexOf(roadbook.id);
            if(index == -1) selectedRoadbooks.value.push(roadbook.id)
            else{
                selectedRoadbooks.value.splice(index,1)
            }
        }
    }

    function editRoadook(roadbook:any){
        if(selectionEnabled.value == true){

        }
        else{
            router.push({path: `/roadbooks/${roadbook.id}/edit/${roadbook.type}`})
        }
    }

    function isSelected(roadbook:any){
        return (selectedRoadbooks.value.indexOf(roadbook.id) != -1)
    }

    async function tagSelected(){
        let actionLoader = await loadingController.create({
            message: i18n.t('Processing'),
        });
        actionLoader.present()
        for(let roadbookID of selectedRoadbooks.value){
            // await roadbookAPI.delete(roadbookID)
        }
        await fetchRoadbooks();
        actionLoader.dismiss()
    }

    async function archiveSelected(){
        let actionLoader = await loadingController.create({
            message: i18n.t('Processing'),
        });
        actionLoader.present()
        for(let roadbookID of selectedRoadbooks.value){
            await roadbookAPI.update(roadbookID, {'archived': true})
        }
        fetchRoadbooks();
        actionLoader.dismiss()
    }

    async function deleteSelected(){
        let actionLoader = await loadingController.create({
            message: i18n.t('Processing'),
        });
        actionLoader.present()
        for(let roadbookID of selectedRoadbooks.value){
            await roadbookAPI.delete(roadbookID)
        }
        await fetchRoadbooks();
        actionLoader.dismiss()
    }

    function toggleFavorite(roadbook:any){
        roadbook.favorite = !roadbook.favorite;
        let data = {favorite: roadbook.favorite};
        roadbookAPI.update(roadbook.id, data).then((res)=>{
            roadbook.favorite = res.favorite;
        });
    }
</script>

<style scoped>
ion-card {
    border-radius: 10px;
}
.selectable{
    border: 1px solid white;
}
.selected{
    border: 1px solid var(--ion-color-tertiary) !important;
}
.favortie {
    z-index: 200;
    position: absolute;
    font-size: 50px;
    background-color: var(--ion-component-background);
    bottom: 0px;
    right: 0px;
    border-radius: 6px;
}
.actions-overlay {
    z-index: 100;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(150,150,150,0.5);
}
.actions-overlay ion-button {
    height: 60px;
    font-size: 20px
}
.actions-overlay ion-button:first-child {
    margin-top: 35%;
}
ion-item-divider {
 height: 5px !important;
 max-height: 5px !important;
 min-height: 5px !important;
}
</style>