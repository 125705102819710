<template>
    <!-- This component have no UI -->
</template>

<script setup lang="ts">
    import { ref, onMounted, watch } from 'vue';
    import { graphhopperAPI } from '@/libs/backend';
    import { switchLatLng, extractLineStrings } from '@/libs/geoUtils'
    import jq from 'jqts';
    import { roadbookEditStore } from '@/stores/RoadbookEditStore';

    var loading = ref(false)
    const response:any = ref(undefined);
    const roadbookStore = roadbookEditStore();

    const props = defineProps({
        type: {
            type: String,
            required: true
        },
    });

    defineExpose({
        calculateRoute,
        calculateRoundtrip,
        loading,
        response
    });
    
    const emit = defineEmits<{
        (e: 'onCalculatingRoute'): void
        (e: 'onRouteCalculated', data:any): void
    }>();


    function calculating(bool:boolean){
        if(bool){
            loading.value = true;
            emit("onCalculatingRoute")
        }
        else{
            loading.value = false;
            emit("onRouteCalculated", response)
        }
    }

    var cmpWapoints:any = JSON.stringify(roadbookStore.value.map_waypoints);
    onMounted(()=>{
        cmpWapoints = JSON.stringify(roadbookStore.value.map_waypoints);
    })

    async function updateRouting(){
        if(!roadbookStore.initialized) return
        if(props.type == 'routing'){
            let mapWaypoints:string = JSON.stringify(roadbookStore.value.map_waypoints) // stringify to compare
            if(cmpWapoints != mapWaypoints){
                cmpWapoints = mapWaypoints;
                calculating(true);
                let routeResult:any = await calculateRoute(roadbookStore.value.map_waypoints);
                response.value = routeResult;
                roadbookStore.value.points = routeResult.points;
                roadbookStore.value.points_sections = routeResult.points_sections;
                roadbookStore.value.waypoints = routeResult.waypoints;
                calculating(false);
            }
        }
        else if(props.type == 'discovery'){
            // DO NOTHING. Code is into RoadbookEditDiscovery.vue for now
        }
    }

    // roadbookStore.$subscribe(async(mutation:any, state) => {
    //     updateRouting()
    // })

    watch(() => roadbookStore.value.map_waypoints, (newValue, oldValue) => {
        updateRouting()
    }, { deep: true });

    /*
        Calculate the route for given wayponts and return navigation points
        Return object :
        {
            "points": Array<Array<Number|Number>> // Lat/Long array
            "sections": Array<Array<Number|Number|String>> // array of start/stop index postion of profile ID
        }
    */
    async function calculateRoute(waypointsProfiled:any[]):Promise<any>{
        let emptyResponse = {points: [], points_sections: [], waypoints:[]}

        if( waypointsProfiled.length < 2){ // at least 2 steps to calculate
            return emptyResponse;
        }

        

        let ghResponse:any = await graphhopperAPI.route({
            "waypoints_profiled": waypointsProfiled,
            "roadbook_id": roadbookStore.isNew() ? null:roadbookStore.id
        })

        if(ghResponse.success == false) ghResponse = emptyResponse
        
        return ghResponse;
    }

    async function calculateRoundtrip(waypoints:any[], heading:number, distance:number, seed:number=0):Promise<Array<Array<Number>>>{
        calculating(true);
        let genetatedLines:any[] = [];

        let waypointsCopy = [...waypoints]; // duplicate array to not edit original one

        waypointsCopy = switchLatLng(waypointsCopy)

        console.log(waypointsCopy)
        console.log('heading', heading, 'distance', distance, "seed", seed)

        let requestParams:any = {
            "points": waypointsCopy,
            "snap_preventions": [
                "motorway",
                "ferry",
                // "tunnel"
            ],
            "custom_model": {
                "priority": [
                    {
                        // type : OTHER, MOTORWAY, TRUNK, PRIMARY, SECONDARY, TRACK, STEPS, CYCLEWAY, FOOTWAY
                        // https://docs.graphhopper.com/#section/Custom-Model/Customizing-distance_influence
                        // List of road_class : https://github.com/graphhopper/graphhopper/blob/1.0-pre39/core/src/main/java/com/graphhopper/routing/profiles/RoadClass.java
                        "if": "road_class == OTHER || road_class == MOTORWAY || road_class == TRACK || road_class == STEPS || road_class == CYCLEWAY || road_class == FOOTWAY || road_class == RESIDENTIAL || road_class == TRACK || road_class == SERVICE",
                        "multiply_by": "0.0"
                    },
                ]
            },
            // "alternative_route.max_paths":0,
            "profile": "car",
            "locale": "fr",
            "instructions": true,
            "calc_points": true,
            // "curbside":"right",
            "elevation":true,
            "algorithm":"round_trip",
            "round_trip.distance":distance,
            "headings": [heading],
            "heading_penalty":1000,
            "pass_through": false, // no u-turn
            "ch.disable":true, // required for round_trip algorythm
            "points_encoded":false, // IMPORTANT, use Graphhopper decoer here else 
                                    // https://github.com/graphhopper/directions-api-js-client/blob/master/src/GHUtil.js#L27
                                    // https://github.com/graphhopper/graphhopper/blob/master/docs/web/api-doc.md#parameters
                                    // https://github.com/graphhopper/graphhopper/blob/d70b63660ac5200b03c38ba3406b8f93976628a6/web/src/main/webapp/js/ghrequest.js#L139
            // other details : , "osm_way_id"
            "details":["street_name", "street_ref", "street_destination", "roundabout", "country", "time", "distance", "max_speed", "max_weight", "max_width", "toll", "road_class", "road_class_link", "road_access", "road_environment", "lanes", "surface", "smoothness"]
        }

        if(seed) requestParams['round_trip.seed'] = seed;

        let ghResponse:any = await graphhopperAPI.routeDiscovery(requestParams)
        response.value = ghResponse;

        if(ghResponse.success == false){
            calculating(false);
            return []
        }

        const jqGhRoutePoints = jq.compile('.paths[].points.coordinates[]')
        genetatedLines = extractLineStrings(jqGhRoutePoints, ghResponse);


        calculating(false);
        return genetatedLines;
    }
</script>

<style scoped></style>