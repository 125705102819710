<template>
    <ion-page>
        <ion-content :fullscreen="true">
            <ion-row class="div-center">
                <ion-col sizeLg="4" offsetLg="4" sizeMd="6" offsetMd="3" sizeSm="12" offsetSm="0">
                    <ion-row>
                        <ion-col size="12">
                            <img id="tripy-logo" src="/tripymap_the_route.svg" alt=""/>
                        </ion-col>
                        <ion-col size="4">
                            <ion-button  expand="block" color="light" :href="backendUrl+'/oauth2/google-oauth2/authorize'">
                                <ion-icon :icon="logoGoogle" size="large"></ion-icon>
                                <ion-label class="ion-margin-start" v-if-desktop>Google</ion-label>
                            </ion-button>
                        </ion-col>
                        <ion-col size="4">
                            <ion-button  expand="block" color="light" :href="backendUrl+'/v1/auth/facebook/authorize'">
                                <ion-icon :icon="logoFacebook" size="large"></ion-icon>
                                <ion-label class="ion-margin-start" v-if-desktop>Facebook</ion-label>
                            </ion-button>
                        </ion-col>
                        <ion-col size="4">
                            <ion-button  expand="block" color="light">
                                <ion-icon :icon="logoApple" size="large"></ion-icon>
                                <ion-label class="ion-margin-start" v-if-desktop>Apple ID</ion-label>
                            </ion-button>
                        </ion-col>
                        <ion-col size="12" class="ion-margin-top">
                            <ion-input type="email" :placeholder="$t('Account email')" v-model="loginField" @ionInput="notVerified=false" v-on:keyup.enter="userLogin" class="login-input">
                                <ion-icon slot="start" :icon="mail" size="large"></ion-icon>
                            </ion-input>
                        </ion-col>
                        <ion-col size="12" class="ion-margin-top">
                            <ion-input type="password" :placeholder="$t('password')" v-model="passwordField" v-on:keyup.enter="userLogin" class="login-input">
                                <ion-icon slot="start" :icon="key" size="large"></ion-icon>
                                <ion-input-password-toggle slot="end"></ion-input-password-toggle>
                            </ion-input>
                        </ion-col>
                        <ion-col v-if="notVerified" size="12" class="ion-margin-top ion-text-center">
                            {{$t('Your email is not verified')}}
                            <ion-button @click="requestAccountVerification" expand="block" fill="clear" shape="round">
                                {{$t('Send verification email')}}
                            </ion-button>
                        </ion-col>
                        <ion-col size="12" class="ion-margin-top">
                            <ion-button @click="userLogin" expand="block" color="tertiary" size="large">
                                <b>{{$t('Login')}}</b>
                            </ion-button>
                        </ion-col>
                    </ion-row>
                    <ion-button router-link="/signup" expand="block" fill="clear" shape="round">
                        {{$t('Sign up')}}
                    </ion-button>
                    <ion-button router-link="/resetpassword" expand="block" fill="clear" shape="round">
                        {{$t('Forgotten password ?')}}
                    </ion-button>

                    <TripyWebsiteButton></TripyWebsiteButton>
                </ion-col>
            </ion-row>
        </ion-content>
    </ion-page>
</template>

<script setup lang="ts">
    import { ref } from 'vue';
    import { login } from '@/services/authService'
    import { key, logoApple, logoFacebook, logoGoogle, mail } from 'ionicons/icons';
    import { authAPI, oauth2API } from '@/libs/backend'
    import { presentToast } from '@/libs/userExperience';
    import { useI18n } from 'vue-i18n';
    import TripyWebsiteButton from '@/components/TripyWebsiteButton.vue';

    const backendUrl = import.meta.env.VITE_TRIPYMAP3_BACKEND
    
    const loginField = ref('');
    const passwordField = ref('');

    const i18n = useI18n();
    const notVerified = ref(false)

    async function userLogin(){
        await login(loginField.value, passwordField.value).then((data)=>{
            if(data.detail == 'LOGIN_USER_NOT_VERIFIED'){
                notVerified.value = true;
            }
            if(data.detail == 'LOGIN_BAD_CREDENTIALS'){
                presentToast(i18n.t('Incorrect email or password'), 'danger')
            }
        });
    }

    function requestAccountVerification(){
        authAPI.requestAccountVerification(loginField.value).then((data)=>{
            if(data && data.success == false){
                presentToast(i18n.t('An error occured'), 'danger')
            }
            else{
                notVerified.value = false;
                presentToast(i18n.t('An email has been sent to') + ' ' + loginField.value)
            }
        })
    }
</script>

<style scoped>
    @import '@/theme/login.css';

    #tripy-logo{
        margin-bottom: 60px;
    }
</style>