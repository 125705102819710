<template>
    <ion-modal ref="modal" mode="ios" @didPresent="focusSearchDestination" @didDismiss="emit('close')">
        <ion-header>
            <ion-toolbar>
                <ion-title color="primary" mode="md" class="ion-text-center">{{$t('Where do we go ?')}}</ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content>
            <ion-row class="ion-margin-top">
                <ion-col size="12">
                    <ion-text class="ion-text-left ion-margin-start modal-section ion-text-uppercase">
                        <ion-icon src="/start.svg"></ion-icon>
                        {{$t('Departure')}}
                    </ion-text>
                    
                    <ion-chip :outline="myPositionDeparture" :color="myPositionDeparture? 'success':'primary'" @click="myPositionDeparture = !myPositionDeparture" class="ion-float-right ion-no-margin">
                        <ion-icon :icon="locate"></ion-icon>
                        <ion-label>{{$t('My location')}}</ion-label>
                    </ion-chip>
                </ion-col>

                <ion-col size="12">
                    <ion-card v-if="!myPositionDeparture" class="search-card">
                        <ion-item v-if="!selectedDeparture" class="ion-no-padding" lines="none">
                            <ion-searchbar v-model="departure" :placeholder="$t('Departure')" inputmode="text" type="text" showCancelButton="never"
                            :debounce="100" @ionInput="searchDeparture($event)"></ion-searchbar>
                            <ion-button expand="block" fill="clear" shape="round" @click="favorites.present('departure')">
                                {{$t('Favorite')}}
                                <ion-icon slot="start" :icon="star"></ion-icon>
                            </ion-button>
                        </ion-item>
                        <ion-item v-else button @click="selectedDeparture = undefined" color="success">
                            <ion-icon slot="start" :icon="checkmarkCircle"></ion-icon>
                            <ion-label>{{selectedDeparture.display_name||selectedDeparture.label}}</ion-label>
                        </ion-item>
                        <ion-list v-if="!isDepartureSelected()">
                            <ion-item v-for="result of resultsDeparture" button @click="selectedDeparture = result">
                                <ion-label>{{result.display_name}}</ion-label>
                            </ion-item>
                        </ion-list>
                    </ion-card>
                </ion-col>

                <ion-col size="12">
                    <ion-text class="ion-margin-top ion-margin-start modal-section ion-text-uppercase">
                        <ion-icon src="/stop.svg"></ion-icon>
                        {{$t('Destination')}}
                    </ion-text>
                </ion-col>

                <ion-col size="12">
                    <ion-card class="search-card">
                        <ion-item v-if="!selectedDestination" class="ion-no-padding" lines="none">
                            <ion-searchbar v-model="destination" ref="destinationSearchbar" :placeholder="$t('Destination')" inputmode="text" type="text" showCancelButton="never"
                            :debounce="100" @ionInput="searchDestination($event)"></ion-searchbar>
                            <ion-button  expand="block" fill="clear" shape="round" @click="favorites.present('destination')">
                                {{$t('Favorite')}}
                                <ion-icon slot="start" :icon="star"></ion-icon>
                            </ion-button>
                        </ion-item>
                        <ion-item v-else button @click="selectedDestination = undefined" color="success">
                            <ion-icon slot="start" :icon="checkmarkCircle"></ion-icon>
                            <ion-label>{{selectedDestination.display_name||selectedDestination.label}}</ion-label>
                        </ion-item>
                        <ion-list v-if="!isDestinationSelected()">
                            <ion-item v-for="result of resultsDestination" button @click="selectedDestination = result">
                                <ion-label>{{result.display_name}}</ion-label>
                            </ion-item>
                        </ion-list>
                    </ion-card>
                </ion-col>
            </ion-row>
        </ion-content>
        <ion-footer>
            <ion-toolbar>
                <ion-button expand="block" fill="clear" shape="round" class="ion-float-left" @click="close" color="light">
                    <ion-icon slot="start" :icon="map"></ion-icon>
                    {{$t('Display the Map')}}
                </ion-button>
                <ion-button expand="block" class="ion-float-right ion-margin-end" @click="route" :disabled="!isDepartureSelected() || !isDestinationSelected()">
                    {{$t('OK !')}}
                </ion-button>
            </ion-toolbar>
        </ion-footer>

        <FavoritesModal ref="favorites" @select="pickFavotite"></FavoritesModal>
    </ion-modal>
</template>

<script setup lang="ts">
    import { ref, onMounted } from 'vue';
    import { checkmarkCircle, map, locate, star } from 'ionicons/icons';
    import { locationAPI } from '@/libs/backend';
    import FavoritesModal from '@/components/FavoritesModal.vue';


    // variables refs
    const myPositionDeparture = ref(false);
    const departure = ref('');
    const destination = ref('');
    const selectedDeparture:any = ref(undefined);
    const selectedDestination:any = ref(undefined);
    const resultsDeparture:any = ref([]);
    const resultsDestination:any = ref([]);
    const loading = ref(false);

    // HTML components refs
    const modal = ref();
    const destinationSearchbar = ref();
    const favorites = ref();

    const props = defineProps({
        autoPresent: {
            type: Boolean,
            default: true,
        }
    });

    defineExpose({
        present,
        close,
    });

    // Component event emission
    const emit = defineEmits<{
        (e: 'route', from:any, to:any): void
        (e: 'close'): void
    }>();

    onMounted(()=>{
        if(props.autoPresent){
            present();
        }
    });

    function present(){
        modal.value.$el.present()
    }

    function close(){
        modal.value.$el.dismiss()
    }

    function route(){
        emit('route', placeToCoordinate(selectedDeparture.value), placeToCoordinate(selectedDestination.value))
        close();
    }

    function placeToCoordinate(place:any){
        if(place.lat && place.lon) return [place.lat, place.lon]    // Nominatim data
        if(place.coordinate ) return place.coordinate               // favorite
        return undefined
    }

    async function searchDeparture(event:any) {
        loading.value = true;
        let query = event.target.value.toLowerCase();
        resultsDeparture.value = await locationAPI.search(query);
        loading.value = false;
    }

    async function searchDestination(event:any) {
        loading.value = true;
        let query = event.target.value.toLowerCase();
        resultsDestination.value = await locationAPI.search(query);
        loading.value = false;
    }

    function isDepartureSelected(){
        return selectedDeparture.value || myPositionDeparture.value == true
    }

    function isDestinationSelected(){
        return selectedDestination.value
    }

    function focusSearchDestination(){
        // only works when Modal opening it terminated with modal @didPresent event
        destinationSearchbar.value.$el.setFocus()
    }
    
    function pickFavotite(favoritePlace:any, purpose:string){
        if(purpose == 'departure'){
            selectedDeparture.value = favoritePlace;
        }
        if(purpose == 'destination'){
            selectedDestination.value = favoritePlace;
        }
    }
    
</script>

<style scoped>
    ion-modal {
        --height: 55%;
        --border-radius: 16px;
        --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    }

    .modal-section {
        font-size: 20px;
    }

    .search-card {
        margin: 0 0 20px 0;
    }

    .toolbar-title {
        text-overflow: unset !important;
    }

    ion-chip.chip-outline {
        background-color: var(--ion-color-success) !important;
        color: white
    }

    ion-content ion-card {
        box-shadow: none;
    }

    ion-segment { 
        width: 100%;
    }
</style>