<template>
    <ion-page>
        <ion-content :fullscreen="true">
            <ion-row class="div-center">

                <ion-col sizeLg="4" offsetLg="4" sizeMd="6" offsetMd="3" sizeSm="12" offsetSm="0" v-on:keyup.enter="userSignUp">
                    <ion-row>
                        <ion-col size="12" class="ion-text-center">
                            <img class="tripy-white-logo" src="/tripy_white.svg" />
                        </ion-col>
                        <ion-col size="12" class="ion-text-center">
                            <h1 v-if="waitConfirmation">{{$t('Account validation')}}</h1>
                            <h1 v-else>{{$t('Account creation')}}</h1>
                        </ion-col>
                    </ion-row>
                
                    <ion-row v-if="!showForm && !waitConfirmation" class="ion-text-center ion-margin-top">
                        <ion-col size="12" class="ion-margin-top">
                            <b>{{ $t('with') }}</b>
                        </ion-col>
                        <ion-col size="4">
                            <ion-button  expand="block" color="light" :href="backendUrl+'/oauth2/google-oauth2/authorize'">
                                <ion-icon :icon="logoGoogle" size="large"></ion-icon>
                                <ion-label class="ion-margin-start" v-if-desktop>Google</ion-label>
                            </ion-button>
                        </ion-col>
                        <ion-col size="4">
                            <ion-button  expand="block" color="light">
                                <ion-icon :icon="logoFacebook" size="large"></ion-icon>
                                <ion-label class="ion-margin-start" v-if-desktop>Facebook</ion-label>
                            </ion-button>
                        </ion-col>
                        <ion-col size="4">
                            <ion-button  expand="block" color="light">
                                <ion-icon :icon="logoApple" size="large"></ion-icon>
                                <ion-label class="ion-margin-start" v-if-desktop>Apple ID</ion-label>
                            </ion-button>
                        </ion-col>
                        <ion-col size="12" class="ion-margin-top">
                            <b>{{ $t('or') }}</b>
                        </ion-col>
                        <ion-col size="12">
                            <ion-button @click="showForm = true" expand="block" color="primary" size="large">
                                <b>{{ $t('With my email') }}</b>
                            </ion-button>
                        </ion-col>
                    </ion-row>

                    <div v-if="showForm">
                        <ion-row>
                            <ion-col size="12">
                                <ion-input ref="usernameInput" type="text" :placeholder="$t('username')" v-model="username" @ionInput="verifyUsername" :error-text="usernameError" class="signup-input"></ion-input>
                                <div v-if="userNameSpecialCharError">
                                    <ion-text color="warning">
                                        {{ $t('dot') }}<ion-chip color="medium" outline="true"><ion-label class="char-allowed">.</ion-label></ion-chip>
                                        {{ $t('dash') }}<ion-chip color="medium" outline="true"><ion-label class="char-allowed">-</ion-label></ion-chip>
                                        {{ $t('underscore') }}<ion-chip color="medium" outline="true"><ion-label class="char-allowed">_</ion-label></ion-chip>
                                    </ion-text>
                                </div>
                            </ion-col>
                            <ion-col size="12">
                                <ion-input ref="emailInput" type="email" :placeholder="$t('email')" v-model="email" :error-text="$t('Required field')" class="signup-input"></ion-input>
                            </ion-col>
                        </ion-row>

                        <PasswordForm ref="passwordForm" v-if="showForm" @update:password="(p) => password=p"></PasswordForm>

                        <ion-row>
                            <ion-col size="12" class="ion-margin-top">
                                <ion-button @click="userSignUp" :disabled="loading" expand="block" color="primary" size="large">
                                    <b v-if="!loading">{{ $t('Confirm') }}</b>
                                    <ion-spinner v-if="loading" name="crescent"></ion-spinner>
                                </ion-button>
                            </ion-col>
                        </ion-row>
                    </div>

                    <CheckMailbox v-if="waitConfirmation" :email="email"></CheckMailbox>

                    <ion-button router-link="/login" expand="block" fill="clear" shape="round" class="ion-margin-top">
                        {{$t('Back to Login')}}
                    </ion-button>



                </ion-col>
            </ion-row>
        </ion-content>
    </ion-page>
</template>

<script setup lang="ts">
    import { authAPI, oauth2API } from '@/libs/backend';
    import { showIonInputError, presentToast } from '@/libs/userExperience';
    import { logoApple, logoFacebook, logoGoogle } from 'ionicons/icons';
    import { ref, onUpdated } from 'vue';
    import { useI18n } from 'vue-i18n';
    import CheckMailbox from '@/components/CheckMailbox.vue';
    import PasswordForm from '@/components/PasswordForm.vue';
    
    const backendUrl = import.meta.env.VITE_TRIPYMAP3_BACKEND

    const username = ref('');
    const usernameError = ref('');
    const userNameSpecialCharError = ref(false);
    const email = ref('');
    const password = ref('');
    const loading = ref(false);
    const waitConfirmation = ref(false)
    const showForm = ref(false);
    
    // HTML elements refs
    const usernameInput = ref();
    const emailInput = ref();
    const passwordForm = ref();

    const i18n = useI18n();

    onUpdated(()=>{
        showForm.value = false;
        loading.value = false;
    })


    async function userSignUp(){
        let error = false;
        error = showIonInputError(usernameInput, !username.value) || error;
        error = showIonInputError(emailInput, !email.value) || error;

        error = await verifyUsername() || error
        error = passwordForm.value.hasError() || error;


        if(error)
            return
    
        let signupData = {
            email:email.value, 
            password:password.value,
            username:username.value
        }
        authAPI.signup(signupData).then((res)=>{
            if(!res.detail){ // sign up error will throw a detail
                presentToast(i18n.t('Successfully registered'))

                showForm.value = false
                waitConfirmation.value = true
            }
            else if (res.detail == 'USER_EMAIL_ALREADY_EXISTS') presentToast(i18n.t('USER_EMAIL_ALREADY_EXISTS'), 'warning')   
            else if(res.detail == 'REGISTER_USER_ALREADY_EXISTS') presentToast(i18n.t('REGISTER_USER_ALREADY_EXISTS'), 'warning');
            else if(res.detail == 'USERNAME_ALREADY_EXISTS') presentToast(i18n.t('USERNAME_ALREADY_EXISTS'), 'warning');
        },
        (error)=>{
            console.log('signup THEN ERROR')
        });
    }

    async function verifyUsername(){
        usernameError.value = '';
        userNameSpecialCharError.value = false;
        
        if (username.value.length == 0){
            usernameError.value = i18n.t('Required field') 
        }
        else if (username.value.length < 4){
            usernameError.value = i18n.t('Username is minimum 4 characters') 
        }
        else if ( ! /^[a-zA-Z0-9\.\-_]*$/.test(username.value)) {
            userNameSpecialCharError.value = true
            usernameError.value = i18n.t('Only those special characteres are allowed')
        }
        else if(username.value.length >= 4){
            authAPI.nextErrorToastDisabled = true;
            let data = await authAPI.verifyUsername(username.value);
            if( ! data.success){
                usernameError.value = i18n.t('Username is already used')     
            }
        }

        let hasError:boolean = (usernameError.value != '' || userNameSpecialCharError.value == true)
        showIonInputError(usernameInput, hasError);

        return hasError;
    }

</script>

<style scoped>
    @import '@/theme/login.css';

    .char-allowed{
        font-family: Verdana, Geneva, Tahoma, sans-serif !important;
        font-size: 15px;
        font-weight: bold;
    }
</style>