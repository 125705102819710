<template>
    <ion-page>
        <ion-header>
            <ion-toolbar>
                <BackNavButton></BackNavButton>
                <ion-title>{{ $t("Parameters") }}</ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content>
            <ion-list>
                <ion-list-header>
                    <h1>{{ $t('Application') }}</h1>
                </ion-list-header>
                <ion-item>
                    <ion-label>{{$t('Language')}}</ion-label>
                    <LanguageSelect v-model:language="userSettings.language" slot="end"></LanguageSelect>
                </ion-item>
            </ion-list>
            <ion-list>
                <ion-list-header>
                    <h1>{{ $t('Map') }}</h1>
                </ion-list-header>
                <ion-list-header>
                    <ion-text><h3>{{$t('Favorite places')}}</h3></ion-text>
                </ion-list-header>
                <ion-item>
                    <ion-label slot="start">{{$t('Home')}}</ion-label>
                    <ion-icon slot="start" :icon="homeOutline"></ion-icon>
                </ion-item>
                <ion-item v-for="place of userSettings.favortiePlaces">
                    <ion-label>{{place.name}}</ion-label>
                </ion-item>
                <ion-item>
                    <ion-label>{{$t('Default map style')}}</ion-label>
                    <ion-item>
                        <ion-select value="openstreetmap">
                            <ion-select-option value="openstreetmap">OpenStreetMap</ion-select-option>
                            <ion-select-option value="other...">TODO</ion-select-option>
                        </ion-select>
                    </ion-item>
                </ion-item>
                <ion-item>
                    <ion-label>{{$t('Default POIs')}}</ion-label>
                    <ion-item>
                        <ion-select value="stations">
                            <ion-select-option value="stations">Petrol station</ion-select-option>
                            <ion-select-option value="other...">TODO</ion-select-option>
                        </ion-select>
                    </ion-item>
                </ion-item>
                <ion-item>
                    <ion-label>{{$t('Display zoom buttons')}}</ion-label>
                    <ion-toggle></ion-toggle>
                </ion-item>
            </ion-list>
        </ion-content>
    </ion-page>
</template>

<script setup lang="ts">
    import { ref, onMounted, onUpdated } from 'vue'
    import { refreshData } from '@/libs/userExperience'
    import { homeOutline } from 'ionicons/icons';
    import LanguageSelect from '@/components/LanguageSelect.vue';
    import BackNavButton from '@/components/BackNavButton.vue';


    var userSettings = ref({
        "language": "fr-fr",
        "favortiePlaces": [{'name':'Maison vacance', latlng:[]},{'name':'Boulot', latlng:[]}]
    })

    onUpdated(()=>{
        refreshData(fetchParameters)
    });

    onMounted(()=>{
        fetchParameters()
    });

    function fetchParameters(){
        
    }
</script>

<style scoped>
</style>