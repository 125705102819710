import { defineStore } from 'pinia';
import { Geolocation } from '@capacitor/geolocation';

export const geoLocationStore = defineStore({
    id: 'geolocation',
    state: () => ({
        geolocationOptions: {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
        },
        location: <any>undefined,
        permission: 'denied',
    }),
    actions: {
        setPosition(position:any){
            if(position){
                let loc = [position.coords.latitude, position.coords.longitude]
                if(JSON.stringify(loc) != JSON.stringify(this.location)){
                    this.location = loc;
                }
            }
        },
        async requestPosition(){
            try{
                if(await this.checkPermissions() == 'granted'){
                    Geolocation.watchPosition(this.geolocationOptions, this.setPosition)
                }
                else{
                    const coordinates:any = await Geolocation.getCurrentPosition(this.geolocationOptions);
                    await this.checkPermissions(); // retrieve permissions again
                    this.setPosition(coordinates);
                }
            }
            catch{
                this.location = undefined
            }
        },
        async checkPermissions(){
            let locPermission = 'error'
            let permission:any = await Geolocation.checkPermissions();
            if(permission && permission.location){
                locPermission = permission.location
            }
            this.permission = locPermission;
            return locPermission
        },
    }
});