<template>
    <ion-fab :vertical="vertical" :horizontal="horizontal" class="locate-btn">
        <ion-fab-button v-if="geoLocation.location && geoLocation.permission == 'granted'" @click="emit('focus')">
            <ion-icon size="large" :icon="locateSharp" color="light"></ion-icon>
        </ion-fab-button>
        <ion-fab-button v-else-if="!geoLocation.location && geoLocation.permission == 'granted'" color="light">
            <ion-icon size="large" :icon="helpOutline"></ion-icon>
        </ion-fab-button>
        <ion-fab-button v-else="!currentLocation && geoLocation.permission == 'prompt'" @click="geoLocation.requestPosition()" color="danger">
            <ion-icon size="large" :icon="locateSharp" color="light"></ion-icon>
        </ion-fab-button>
    </ion-fab>
</template>

<script setup lang="ts">
    import { helpOutline, locateSharp } from 'ionicons/icons';
    import { onMounted } from 'vue';
    import { geoLocationStore } from "@/stores/geoLocationStore";

    const geoLocation = geoLocationStore();

    // Component event emission
    const emit = defineEmits(['focus']);
       
    const props = defineProps({
        vertical: {
            type: String,
            default: 'bottom',
        },
        horizontal: {
            type: String,
            default: 'end',
        }
    });

    onMounted(async()=>{
        if(! geoLocation.location) geoLocation.requestPosition();
    });

</script>

<style scoped>
.locate-btn {
    bottom: 80px;
}
</style>
