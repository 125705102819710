<template>
    <ion-app class="main-app">
    <!-- TODO déplacer le swiper dans Dashboard si toujours présent sur la version Desktop -->
      <!-- <swiper v-if-desktop :modules="[Autoplay]" class="custom-swiper swiper-desktop" :autoplay="true" :loop="true" :delay="4">
        <swiper-slide>
          <img src="/backgrounds/2.jpg"/>
        </swiper-slide>
        <swiper-slide>
          <img src="/backgrounds/3.jpg"/>
        </swiper-slide>
        <swiper-slide>
          <img src="/backgrounds/4.jpg"/>
        </swiper-slide>
        <swiper-slide>
          <img src="/backgrounds/5.jpg"/>
        </swiper-slide>
        <swiper-slide>
          <img src="/backgrounds/6.jpg"/>
        </swiper-slide>
        <swiper-slide>
          <img src="/backgrounds/7.jpg"/>
        </swiper-slide>
        <swiper-slide>
          <img src="/backgrounds/8.jpg"/>
        </swiper-slide>
      </swiper>
       -->
  
      <ion-split-pane v-if="menuType != false" content-id="main-content">
        <NavigationMenu v-if="menuType == 'navigation'" content-id="main-content"></NavigationMenu>
        <!-- To add other menu types here, go to router/index.ts and edit meta.sidemenu informations -->
        <ion-router-outlet id="main-content"></ion-router-outlet>
      </ion-split-pane>
      <ion-router-outlet v-else id="main-content"></ion-router-outlet>
    </ion-app>
  </template>
  
  
  <script setup lang="ts">
    import { useRoute, useRouter } from 'vue-router';
    import NavigationMenu from './components/NavigationMenu.vue';
    import { ref, onMounted, inject } from 'vue';
    /////////////////////////////////////////////////
    // DOCUMENTATION https://swiperjs.com/vue#usage
    /////////////////////////////////////////////////
    import { Swiper, SwiperSlide } from 'swiper/vue';
    import 'swiper/css';
    import '@ionic/vue/css/ionic-swiper.css';
    import 'swiper/css/autoplay';
    import {Autoplay} from 'swiper/modules';
  
  
    const isDesktop = inject('isDesktop')
    const isMobile = inject('isMobile')
    var menuType:any = ref(false);
    
    const router = useRouter();
  
    router.afterEach(()=>{
      isMenuEnabled()
    })
  
    function isMenuEnabled(){
      menuType.value = useRoute().meta.sidemenu;
    }  
  
    onMounted(()=>{
      isMenuEnabled();
    });
  </script>
  
  <style scoped>
  .custom-swiper {
    z-index:-10 ; position:absolute; top:0px; left:0; width: 100%; height: 100%;
  }
  .swiper-desktop {
    -ms-transform: scale(1.5); /* IE 9 */
    -webkit-transform: scale(1.5); /* Safari 3-8 */
    transform: scale(1.5);
  }
  .swiper-mobile {
    -ms-transform: scale(3.5); /* IE 9 */
    -webkit-transform: scale(3.5); /* Safari 3-8 */
    transform: scale(3.5);
  }
  </style>
  