<template>
    <ion-modal ref="modal" mode="ios">
        <ion-header>
            <ion-toolbar>
                <ion-title>
                    <ion-icon :icon="star" color="primary" size="medium"></ion-icon>
                    {{ $t('Favorite') }}
                </ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content scrollY="true">
            <ion-searchbar v-if="favoritePlaces.places.length > 1" v-model="searchQuery" @ionInput="search($event)" :placeholder="$t('Search')" inputmode="decimal" type="decimal" showCancelButton="never"></ion-searchbar>
            <ion-text v-if="!favoritePlaces.places.length">
                <h4 class="ion-margin">{{$t('No favorite saved for now')}}</h4>
            </ion-text>
            <ion-list v-if="favoritePlaces.places.length">
                <ion-item v-for="favorite of places" :button="!edit" @click="select(favorite)">
                    <ion-label>{{favorite.label}}</ion-label>
                    <ion-label>{{favorite.address}}</ion-label>
                    <ion-buttons v-if="edit" slot="end">
                        <ion-button @click="">
                            <ion-icon slot="icon-only" :icon="createOutline"></ion-icon>
                        </ion-button>
                        <ion-button @click="">
                            <ion-icon slot="icon-only" :icon="trashOutline" color="danger"></ion-icon>
                        </ion-button>
                    </ion-buttons>
                </ion-item>
            </ion-list>
        </ion-content>
        <ion-footer>
            <ion-toolbar>
                <ion-button @click="close()" expand="block" size="medium" fill="outline" color="light">
                    {{ $t('Close') }}
                </ion-button>
            </ion-toolbar>
        </ion-footer>
    </ion-modal>
</template>

<script setup lang="ts">
    import { onMounted, Ref, ref } from 'vue';
    import { closeOutline, createOutline, star, trashOutline } from 'ionicons/icons';
    import { favoritePlaceStore } from '@/stores/FavoritePlaceStore'

    // HTML components refs
    const modal = ref();
    // Store
    const favoritePlaces = favoritePlaceStore();
    // Variables
    const allPlaces:any = ref([]);
    const places:any = ref([]);
    const searchQuery = ref()
    const purpose:Ref<string> = ref('') // used to specify returned purpose to other components

    const props = defineProps({
        edit: {
            type: Boolean,
            default: false,
        }
    });

    defineExpose({
        present,
        close,
    });

    // Component event emission
    const emit = defineEmits<{
        (e: 'select', data:any, purpose:string): void
    }>();

    onMounted(async()=>{
        allPlaces.value = await favoritePlaces.fetch()
        places.value = allPlaces.value;
    });

    function present(givenPurpose:string=''){
        modal.value.$el.present();
        purpose.value = givenPurpose;
    }

    function close(){
        modal.value.$el.dismiss()
    }

    function search(event:any){
        let query = event.target.value.toLowerCase();
        if(query.length)
            places.value = places.value.filter((v: any) => v.label.includes(query));
        else
            places.value = allPlaces.value;
    }

    function select(favorite:any){
        emit('select', favorite, purpose.value);
        close();
    }
</script>

<style scoped>
</style>