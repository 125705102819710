import { defineStore } from 'pinia';

export const authTokenStore = defineStore({
    id: 'auth',
    actions: {
        token(){
            return localStorage.getItem('auth_token');
        },
        store(token:string) {
            localStorage.setItem('auth_token', token);
        },
        clear() {
            localStorage.removeItem('auth_token');
        }
    }
});