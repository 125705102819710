<template>
    <ion-button v-if-desktop :href="tripyWebsite" target="_blank" expand="block" fill="clear" shape="round">
        {{tripyWebsiteLabel}}
        <MaterialSymbol name="open_in_new"></MaterialSymbol>
    </ion-button>
</template>

<script setup lang="ts">
    import { MaterialSymbol } from '@dbetka/vue-material-symbols';
    import { inject } from 'vue';

    const tripyWebsite = inject('tripyWebsite')
    const tripyWebsiteLabel = inject('tripyWebsiteLabel')
</script>