<template>
    <ion-app class="main-app">
      <ion-router-outlet id="main-content" mode="ios" animated="true"></ion-router-outlet>
    </ion-app>
</template>
  
  
<script setup lang="ts">
</script>
  
<style scoped>
    
</style>
  