<template>
    <!-- <ion-header>
        <ion-menu-toggle>
            <ion-button fill="clear" shape="round" color="light" size="large">
            <ion-icon slot="icon-only" :icon="menu"></ion-icon>
            </ion-button>
        </ion-menu-toggle>
        <ion-toolbar>
        <ion-item lines="none">
            <ion-title>
                <b>{{ $t("Welcome to")}} <AppName color="primary"></AppName></b>
            </ion-title>
            </ion-item>
        </ion-toolbar>
        <LanguageSelect v-if-desktop v-model:language="userSettings.language" slot="end"></LanguageSelect>
    </ion-header> -->
    <ion-content :fullscreen="true">
        <div class="ion-margin" style="z-index: 10;">
            <ion-row>
                <ion-col size="12" class="ion-text-center">
                    <ion-text>
                    <!-- <h2 class="strong">{{ $t("The pleasure of the road, nothing else") }}</h2> -->
                    <h2 class="strong">{{ $t("Start your") }}<span><i><b>TRIP</b></i></span></h2>
                </ion-text>
                </ion-col>
                <ion-col size-md="4" size-sm="4" size-xs="12">
                <ion-card class="card-hover ion-no-margin">
                    <ion-card-header v-if-desktop>
                        <ion-card-title color="primary">EXPRESS</ion-card-title>
                    </ion-card-header>
                    <ion-card-content v-if-desktop>
                    <!-- <MaterialSymbol name="bolt" size="600"></MaterialSymbol> -->
                    <Vue3Lottie ref="lottieExpress" :animationData="Express" :autoPlay="false" @mouseover="lottieExpress.play()" @mouseleave="lottieExpress.pause()"/>
                    </ion-card-content>
                    <ion-card-content v-if-mobile>
                        <ion-button  expand="block" fill="clear" shape="round">
                            <MaterialSymbol name="bolt"></MaterialSymbol>
                            EXPRESS
                        </ion-button>
                    </ion-card-content>
                </ion-card>
                </ion-col>
                <ion-col size-md="4" size-sm="4" size-xs="12">
                <ion-card class="card-hover  ion-no-margin">
                    <ion-card-header v-if-desktop>
                    <ion-card-title color="primary">BOOMERANG</ion-card-title>
                    </ion-card-header>
                    <ion-card-content v-if-desktop>
                    <Vue3Lottie ref="lottieBoomerang" :animationData="Boomerang" :autoPlay="false" @mouseover="lottieBoomerang.play()" @mouseleave="lottieBoomerang.pause()"/>
                    <!-- <MaterialSymbol name="laps" size="600"></MaterialSymbol> -->
                    </ion-card-content>
                    <ion-card-content v-if-mobile>
                    <ion-button  expand="block" fill="clear" shape="round">
                        <MaterialSymbol name="laps"></MaterialSymbol>
                        LOOPER
                    </ion-button>
                    </ion-card-content>
                </ion-card>
                </ion-col>
                <ion-col size-md="4" size-sm="4" size-xs="12">
                <ion-card class="card-hover  ion-no-margin">
                    <ion-card-header v-if-desktop>
                    <ion-card-title color="primary">EXPERT</ion-card-title>
                    </ion-card-header>
                    <ion-card-content v-if-desktop>
                    <!-- <MaterialSymbol name="stylus_note" size="600"></MaterialSymbol> -->
                    <Vue3Lottie ref="lottieExpert" :animationData="Test6" :autoPlay="false" @mouseover="lottieExpert.play()" @mouseleave="lottieExpert.pause()"/>
                    </ion-card-content>
                    <ion-card-content v-if-mobile>
                    <ion-button  expand="block" fill="clear" shape="round">
                        <MaterialSymbol name="stylus_note"></MaterialSymbol>
                        EXPERT
                    </ion-button>
                    </ion-card-content>
                </ion-card>
                </ion-col>


                <!-- <ion-col size="4">
                    <RoadbookType discovery @click="createRoadbook('discovery')" class="card-hover"></RoadbookType>
                </ion-col>
                <ion-col size="4">
                    <RoadbookType discovery @click="createRoadbook('discovery')" class="card-hover"></RoadbookType>
                </ion-col>
                <ion-col size="4">
                    <RoadbookType routing @click="createRoadbook('routing')" class="card-hover"></RoadbookType>
                </ion-col> -->
            </ion-row>

        </div>

        <ion-row v-if="lastEditedRoadbooks && lastEditedRoadbooks.length">
            <ion-col size="12">
                <ion-card>
                    <ion-card-header>
                        <ion-card-title>{{ $t("Last activities") }}</ion-card-title>
                    </ion-card-header>
                    <ion-card-content>
                        <ion-list>
                            <ion-item v-for="roadbook of lastEditedRoadbooks" button :router-link="'/roadbooks/'+roadbook.id+'/edit/'+roadbook.type">
                                <ion-icon :icon="roadbook.roundtrip ? reloadOutline:analyticsOutline" color="tertiary"></ion-icon>
                                <ion-text class="ion-margin-start ion-margin-end">{{ roadbook.title }}</ion-text>
                                <!-- <ion-chip color="primary" mode="ios">
                                    <ion-label>{{$t(roadbook.type)}}</ion-label>
                                </ion-chip> -->
                            </ion-item>
                        </ion-list>
                    </ion-card-content>
                </ion-card>
            </ion-col>
        </ion-row>

        <!-- <ion-row class="bottom">
            <ion-col size="12">
            <ion-item lines="none" color="none">
                <ion-button slot="end" router-link="/credits" fill="clear" shape="round">
                    <ion-icon slot="start" :icon="informationCircle" size="large"></ion-icon>
                    {{ $t("Credits") }}
                </ion-button>
            </ion-item>
            </ion-col>
        </ion-row> -->
    </ion-content>

</template>

<script setup lang="ts">
  import { analyticsOutline, reloadOutline } from 'ionicons/icons';
  import { MaterialSymbol } from '@dbetka/vue-material-symbols';
  import AppName from '@/components/AppName.vue';
  import RoadbookType from '@/components/RoadbookType.vue';
  import { BackendAPI } from '@/libs/backend';
  import { ref, onUpdated, onMounted } from 'vue'
  import { refreshData } from '@/libs/userExperience'
  import CreateRoadbookModal from '@/components/CreateRoadbookModal.vue';
  import { useRouter } from 'vue-router';
  import LanguageSelect from '@/components/LanguageSelect.vue';
  import NavigationMenuMobile from '@/components/NavigationMenuMobile.vue';
  import { Vue3Lottie } from 'vue3-lottie'
  // Lottie tests : DOC at https://www.npmjs.com/package/vue3-lottie
  import Test1 from '@/components/lottie/Animation - 1725462845336.json'
  import Test2 from '@/components/lottie/Animation - 1725462855783.json'
  import Express from '@/components/lottie/Animation - 1725462896756.json'
  import Test3 from '@/components/lottie/Animation - 1725462915401.json'
  import Test4 from '@/components/lottie/Animation - 1725463197959.json'
  import Boomerang from '@/components/lottie/Animation - 1725463217874.json'
  import Test6 from '@/components/lottie/Animation - 1725463252664.json'
  

  const lastEditedRoadbooks:any = ref([])
  const roadbookAPI = new BackendAPI('/roadbook')
  const router = useRouter();

  const lottieExpress = ref()
  const lottieBoomerang = ref()
  const lottieExpert = ref()

  // TODO : from backend + Pinia for local storage
  var userSettings = ref({"language":"fr"})

  onUpdated(()=>{
    refreshData(fetchRoadbooks)
  });

  onMounted(()=>{
    fetchRoadbooks()
  });

  async function fetchRoadbooks(){
    lastEditedRoadbooks.value = await roadbookAPI.all({limit:3, sorting:['-updated_date']});
  }

  function createRoadbook(type:string){
    router.push({path: `/roadbooks/new/edit/${type}`})
  }

</script>


<style scoped>
  ion-toolbar ion-title {
    text-align: center;
  }
  .card-hover:hover{
    /* border: 1px solid var(--ion-color-primary); */
    background: linear-gradient(0.8turn, #FFDE00, rgba(255,0,128), #333F47);
  }
  ion-card-header {
    text-align: center;
  }
  ion-card img {
    width:80%;
    margin-top: 50px;margin-bottom: 30px;margin-left: 35px;
  }
  ion-card ion-button,
  ion-card {
    font-size: 30px;
  }
  ion-card ion-button img { min-width: 50px;}
  ion-card ion-icon { max-width: 100px; }
  ion-card i { 
    font-size: 80px;
    color: white
  }
  ion-card {
    border: 4px solid transparent;
    border-radius: 20px;
    background: 
      linear-gradient(to right, var(--ion-card-background), var(--ion-card-background)), 
      linear-gradient(-0.15turn, var(--ion-color-primary), rgba(255,0,128), #333F47) ; 
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
  }

</style>