import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router';

import { IonicVue } from '@ionic/vue';
import { createPinia } from 'pinia'

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/style.css';

/* Google Material Symbols (icons) */
import materialSymbolsPlugin from '@dbetka/vue-material-symbols';
import 'material-symbols/index.css';

/* Device detection */ 
import { setupDeviceDirectives } from '@/directives/deviceDirectives'

/* Translation */
import { createI18n } from 'vue-i18n'
import { messages } from '@/locales/messages'
export const i18n:any = createI18n({
  legacy: false, // to use Vue3 composition API `<script setup>`
  locale: window.navigator.language ? window.navigator.language:'en',
  fallbackLocale: 'en',
  inheritLocale: true,
  globalInjection: true,
  useScope: "local",
  messages: messages
});

/* Pinia for storage */
const pinia  = createPinia();

/* App creation */
const app = createApp(App)
  .use(IonicVue)
  .use(pinia)
  .use(router)
  .use(i18n)
  .use(materialSymbolsPlugin, {
    defaultWeight: '100',
    defaultGrade: 'bold',
    defaultType: 'sharp',
    defaultFilled: true,
    defaultSize: '1.5em',
  });

/* Import once all Ionic components into app */
import * as IonComponents from '@ionic/vue';
Object.keys(IonComponents).forEach(key => {
  if (/^Ion[A-Z]\w+$/.test(key)) {
    app.component(key, IonComponents[key]);
  }
});

// Popper
import Popper from "vue3-popper";
app.component("Popper", Popper);

import * as Sentry from "@sentry/vue";
if(import.meta.env.PROD){
  Sentry.init({
    app,
    dsn: "https://4513aeeb9137040b1d089ce2f9dc9d84@o4507504779264000.ingest.de.sentry.io/4507504816619600",
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["https://tripymap3.tripy.be", "https://tripymap3.com"],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
  
// Global variables
let website = 'https://www.tripy.eu'
app.provide('tripyWebsite', website)
app.provide('tripyWebsiteLabel', website.replace('https://','').replace('www.',''))
app.provide('tripyEmailContact', 'contactez-nous@tripy.be')


router.isReady().then(async () => {
  await setupDeviceDirectives(app);
  app.mount('#app');
});