<template>
    <ion-list>
        <ion-item lines="none" button @click="dismissWaypoint">
            <ion-icon slot="start" :icon="close" color="danger"></ion-icon>
            <ion-label>{{$t('Remove from map')}}</ion-label>
        </ion-item>
    </ion-list>
</template>

<script setup lang="ts">
    import { MapStore } from '@/stores/mapStore';
    import { close, trash } from "ionicons/icons";
    import { inject } from "vue";

    const mapStore = MapStore();
    const { dismiss }:any = inject('popoverActions') 

    function dismissWaypoint(){
        mapStore.resetFocus();
        dismiss()
    }
</script>

<style scoped>
</style>