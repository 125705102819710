import { defineStore } from 'pinia';

export const MapStore = defineStore({
    /*  
        This Pinia Store is used to share places displayed or not through the Map 
        and share actions with sub components 
    */
    id: 'map',
    state: () => ({
        leafletMap: <any>undefined,
        searchResults: <any>[],
        focusedPlace: <any>undefined,
    }),
    actions: {
        init(map:any){
            this.leafletMap = map;
        },

        resetFocus(){
            this.focusedPlace = undefined;
        },
        focusedLatLon(){
            if(!this.focusedPlace) return []
            return [parseFloat(this.focusedPlace.lat), parseFloat(this.focusedPlace.lon)]
        }
    }
});