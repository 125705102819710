<template>
    <ion-list>
        <ion-item v-if="roadbookStore.isRoutable()" lines="none" button 
            @click="roadbookStore.switchRoundTrip();dismiss($event);">
                <ion-icon slot="start" :src="roadbookStore.isRoundTrip()? 'reload-stroke.svg':'reload.svg'"></ion-icon>
                <ion-label>{{ roadbookStore.isRoundTrip()? $t('Without roundtrip'):$t('roundtrip')}}</ion-label>
        </ion-item>
        <ion-item v-if="roadbookStore.isRoutable() && roadbookStore.isSectionDivisibleBefore(coordinates())" lines="none" button
            @click="roadbookStore.addWaypointBefore(coordinates());dismiss($event)">
            <ion-icon slot="start" src="/step-insert-before.svg"></ion-icon>
            <ion-label>{{ $t('Insert step before')}}</ion-label>
        </ion-item>
        <ion-item v-if="!roadbookStore.isRoundTrip() && roadbookStore.isRoutable()" lines="none" button 
            @click="roadbookStore.switchStartDest();dismiss($event);">
                <ion-icon slot="start" src="swap-start-dest.svg"></ion-icon>
                <ion-label>{{ $t('Swap with start') }}</ion-label>
        </ion-item>
        <ion-item lines="none">
            <ion-icon slot="start" :icon="lockClosed" :color="roadbookStore.options.locked_destination? 'tertiary':'light'" ></ion-icon>
            <ion-row @click="toggleLock($event)">
                <ion-col size="9">
                    <ion-text>{{ $t('Lock destination') }}</ion-text>
                </ion-col>
                <ion-col size="3">
                    <ion-toggle :checked="roadbookStore.options.locked_destination"></ion-toggle>
                </ion-col>
            </ion-row>
        </ion-item>
    </ion-list>
</template>

<script setup lang="ts">
    import { roadbookEditStore } from "@/stores/RoadbookEditStore";
    import { lockClosed } from "ionicons/icons";
    import { inject } from "vue";

    const roadbookStore = roadbookEditStore();
    const { dismiss, coordinates }:any = inject('popoverActions') 

    function toggleLock(event:Event){
        event.stopPropagation()
        roadbookStore.options.locked_destination = !roadbookStore.options.locked_destination;
    }
</script>

<style scoped>
</style>