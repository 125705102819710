<template>
    <ion-fab :vertical="vertical" :horizontal="horizontal" class="fit-btn">
        <ion-fab-button @click="fit()">
            <ion-icon size="large" :icon="scanOutline" color="light"></ion-icon>
        </ion-fab-button>
    </ion-fab>
</template>

<script setup lang="ts">
    import { scanOutline } from 'ionicons/icons';

    // Component event emission
    const emit = defineEmits(['fitted']);
       
    const props = defineProps({
        map: {
            type: Object,
            required: true,
        },
        vertical: {
            type: String,
            default: 'bottom',
        },
        horizontal: {
            type: String,
            default: 'end',
        }
    });

    function fit(){
        props.map.fitPoints();
        emit('fitted')
    }

</script>

<style scoped>
.fit-btn {
    top: 80px;
}
</style>
