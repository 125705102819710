<template>
    <ion-page>
        <ion-content :fullscreen="true">
            <ion-row class="div-center">
                <ion-col sizeLg="4" offsetLg="4" sizeMd="6" offsetMd="3" sizeSm="12" offsetSm="0">
                    <ion-row>
                        <ion-col size="12" class="ion-text-center">
                            <img class="tripy-white-logo" src="/tripy_white.svg" />
                        </ion-col>
                        <ion-col size="12" class="ion-text-center">
                            <h3>{{$t('Change password for')}} <ion-text color="primary">{{ email }}</ion-text></h3>
                        </ion-col>
                    </ion-row>

                    <div v-if="!tokenAlreadyUsed">
                        <PasswordForm ref="passwordForm" @update:password="(p) => password=p"></PasswordForm>

                        <ion-row>
                            <ion-col size="12" class="ion-margin-top">
                                <ion-button @click="changePassword" expand="block" color="tertiary" size="large">
                                    <ion-spinner v-if="loading" name="crescent"></ion-spinner>
                                    <b v-else>{{$t('Send')}}</b>
                                </ion-button>
                            </ion-col>
                        </ion-row>
                    </div>
                    <div v-if="tokenAlreadyUsed">
                        <ion-row>
                            <ion-col size="12" class="ion-margin-top ion-margin-bottom ion-text-center">
                                <ion-icon class="check-icon" color="success" :icon="checkmarkCircle"></ion-icon>
                                <h3>{{$t('This email link has already be used to reset your password')}}</h3>
                            </ion-col>
                        </ion-row>
                    </div>

                    <ion-button v-if="tokenAlreadyUsed" router-link="/resetpassword" expand="block" fill="clear" shape="round" class="ion-margin-top">
                        {{$t('Forgotten password ?')}}
                    </ion-button>

                    <ion-button router-link="/login" expand="block" fill="clear" shape="round">
                        {{$t('Back to Login')}}
                    </ion-button>
                    
                </ion-col>
            </ion-row>

        </ion-content>
    </ion-page>
</template>

<script setup lang="ts">
    import { ref } from 'vue';
    import { authAPI } from '@/libs/backend'
    import { useRoute, useRouter } from 'vue-router';
    import { presentToast } from '@/libs/userExperience';
    import { useI18n } from 'vue-i18n';
    import { checkmarkCircle } from 'ionicons/icons';
    import PasswordForm from '@/components/PasswordForm.vue';

    const route = useRoute();
    const router = useRouter();
    const i18n = useI18n();

    const password = ref('')
    const loading = ref(false)
    const tokenAlreadyUsed = ref(false)
    
    const passwordForm = ref()
    
    const { email, token } = route.params;
    
    function changePassword(){
        if(token && password.value && ! passwordForm.value.hasError()) {
            loading.value = true;
            authAPI.resetPassword(token.toString(), password.value).then((response)=>{
                if(response && response.success == false){
                    tokenAlreadyUsed.value = true;
                }
                else{
                    presentToast(i18n.t('Password succeful changed'))
                    setTimeout(() => {
                        router.push('/login')
                    }, 1500);
                }
            })
        }
    }
</script>

<style scoped>
    @import '@/theme/login.css';

    .check-icon {
        font-size: 3em;
    }
</style>