<template>
    <ion-page>
        <ion-header>
            <ion-toolbar>
                <BackNavButton></BackNavButton>
                <ion-title>{{ $t("Account") }}</ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content :fullscreen="true">
            <ion-button @click="userLogout($event)" color="warning" fill="clear" expand="full">
                <ion-icon slot="icon-only" :icon="powerOutline" class="ion-margin-end"></ion-icon>
                {{ $t('Logout') }}
            </ion-button>
        </ion-content>
    </ion-page>
</template>

<script setup lang="ts">
    import { ref, onUpdated } from 'vue'
    import { powerOutline } from 'ionicons/icons';
    import { refreshData } from '@/libs/userExperience'
    import { logout } from '@/services/authService'
    import BackNavButton from '@/components/BackNavButton.vue';

    onUpdated(()=>{
        refreshData(fetchAccount)
    });

    function fetchAccount(){
        
    }

    function userLogout(e: Event){
        e.preventDefault()
        e.stopPropagation()
        logout();
    }
</script>

<style scoped>
</style>