<template>
    <ion-page>
        <ion-header>
            <ion-toolbar>
                <BackNavButton ref="exit" slot="start" :haveChanges="roadbookStore.isRoutable()" @back="roadbookStore.$reset()" 
                    resetButtons @reset="reset()">
                </BackNavButton>
                <ion-title class="ion-text-center">{{ $t("FLASH TRIP") }}</ion-title>
                <InlineDocumentFieldEdit v-if-desktop :autoUpdate="!roadbookStore.isNew()"  @onUpdated="roadbookStore.value.title=$event"
                    :documentAPI="roadbookAPI" :document="roadbookStore.value" :documentId="roadbookStore.id" 
                    fieldName="title" placeholder="Add a title here">
                </InlineDocumentFieldEdit>
                <MapEditHelpModal ref="userHelp" slot="end"></MapEditHelpModal>
                
                <!-- <ion-buttons slot="end" v-show="roadbookStore.isRoutable()">
                    <ion-button @click="" fill="outline" size="large" class="ion-padding-end">
                        GO !
                    </ion-button>
                </ion-buttons> -->


                
                <ion-progress-bar v-if="calculating || loading" type="indeterminate" color="primary"></ion-progress-bar>
            </ion-toolbar>
        </ion-header>

        <ion-content class="ion-no-padding">
            <Map ref="map"
                 v-model:points="roadbookStore.value.points"
                 v-model:pointsSections="roadbookStore.value.points_sections"
                 v-model:waypoints="roadbookStore.value.map_waypoints"
            >
            </Map>
           
            <MapSearchSheet ref="search" @focusPlace="map.showPlace($event)">
            </MapSearchSheet>
    
            <MapLocateButton ref="locate" vertical="top" horizontal="end" @focus="map.showCurrentLocation()"></MapLocateButton>
            <MapRouteFitButton v-show="roadbookStore.isRoutable()" :map=map vertical="top" horizontal="end" ></MapRouteFitButton>

            <MapProfileSelector ref="profileSelector"></MapProfileSelector>

            <Router ref="routing" :type="RoadbookType"
                @onCalculatingRoute="calculating = true" @onRouteCalculated="calculating = false;">
            </Router>

            <!-- <ion-fab v-show="roadbookStore.isRoutable()" vertical="top" horizontal="center" slot="fixed">
                    <ion-button color="anthracite" class="go-btn ion-no-margin">
                    <ion-text color="primary">
                        Go !!
                    </ion-text>
                    </ion-button>
            </ion-fab> -->

            <ion-fab v-show="roadbookStore.isRoutable()" vertical="bottom" horizontal="end" slot="fixed" @click="saveAndUpload.present()">
                    <ion-button color="anthracite" class="go-btn ion-no-margin">
                    <ion-text color="primary">
                        Go !!
                    </ion-text>
                    </ion-button>
            </ion-fab>
        </ion-content>
        
        <DestinationModal ref="destinationModal" @route="flashRoute"></DestinationModal>
        <SaveAndUploadModal ref="saveAndUpload"></SaveAndUploadModal>
    </ion-page>
    
</template>

<script setup lang="ts">
    import { ref, onMounted, watch } from 'vue';
    import { useRoute } from 'vue-router';
    import InlineDocumentFieldEdit from '@/components/InlineDocumentFieldEdit.vue';

    import Map from '@/components/Map.vue';
    import MapEditHelpModal from '@/components/MapEditHelpModal.vue';
    import Router from '@/components/Router.vue';
    import MapSearchSheet from '@/components/MapSearchSheet.vue';
    import MapProfileSelector from '@/components/MapProfileSelector.vue';
    import BackNavButton from '@/components/BackNavButton.vue';
    import MapLocateButton from '@/components/MapLocateButton.vue';
    import MapRouteFitButton from '@/components/MapRouteFitButton.vue';
    import DestinationModal from '@/components/DestinationModal.vue';
    import SaveAndUploadModal from '@/components/SaveAndUploadModal.vue';
    import { roadbookEditStore } from '@/stores/RoadbookEditStore'
    import { geoLocationStore } from '@/stores/geoLocationStore';
    import { roadbookAPI } from '@/libs/backend';

    // Data stores
    const roadbookStore = roadbookEditStore();
    const geoLocation = geoLocationStore();
    // variables refs
    const loading = ref(false)
    const calculating = ref(false)
    const route = useRoute();
    const roadbookID:any = route.params.id || 'new';
    const RoadbookType ='routing'
    var userLocationFocused = false;
    
    // HTML components refs
    const locate = ref();
    const map = ref();
    const userHelp = ref();
    const mapSettingsModal = ref();
    const search = ref();
    const history = ref();
    const exit = ref();
    const save = ref();
    const routing = ref();
    const profileSelector = ref();
    const saveAndUpload = ref();
    const destinationModal = ref();

    onMounted(()=>{
        // TODO get favorite map layer if present in user settings
        roadbookStore.init(roadbookID, RoadbookType);
        focusUserLocation();

        fitWhenRouted();
    });
    
    watch(() => geoLocation.location, () => {
        focusUserLocation();
    });

    watch(() => roadbookStore.initialized, () => {
        focusUserLocation();
    });

    function focusUserLocation(){
        if(!userLocationFocused && geoLocation.location && roadbookStore.initialized && !roadbookStore.value.points.length) {
            setTimeout(()=>{ // let time to map initialization
                map.value.showCurrentLocation();
                userLocationFocused = true;
            },200)
        }
    }

    function flashRoute(from:any, to:any){
        roadbookStore.setStart(from);
        roadbookStore.setDestination(to);
    }

    function fit(){
        map.value.fitPoints();
    }

    function fitWhenRouted(){
        watch(() =>
            routing.value.response, () => {
                setTimeout(() => { // wait a bit map initialization
                    fit();
                    setTimeout(() => { // wait a bit for map fit
                        profileSelector.value.showUserTip();
                    }, 500);
                }, 200);
            }, { once: true }
        )
    }

    async function reset(){
        await roadbookStore.init('new', 'routing');
        destinationModal.value.present()
        fitWhenRouted();
    }

    document.addEventListener("keydown", (event:KeyboardEvent) => {
        if((navigator.platform.match("Mac") ? event.metaKey : event.ctrlKey)){
            // Replace browser ctrl + S key event
            if (event.keyCode == 83) {
                event.preventDefault();
                if(save.value) save.value.save()
            }
            // Replace browser ctrl + F key event
            if (event.keyCode == 70) {
                event.preventDefault();
                if(search.value) search.value.open();
            }
        }
    }, false);

</script>

<style scoped>
    ion-toolbar ion-title:hover {
        cursor: pointer;
    }

    ion-button.btn-icon-xl {
        width: 70px !important;
        height: 70px !important;
        font-size: 70px !important;
    }

    ion-button.btn-icon-md {
        width: 55px !important;
        height: 55px !important;
        font-size: 55px !important;
    }

    ion-button {
        font-size: 30px !important;
    }

</style>

