<template>
    <ion-buttons v-if-mobile :slot="slot">
        <ion-button @click="back" class="btn-icon-xl">
            <ion-icon slot="icon-only" :icon="caretBack"></ion-icon>
            {{ $t('Back') }}
        </ion-button>
    </ion-buttons>
    <ion-button v-if-desktop :slot="slot" @click="back" class="ion-margin-end" fill="clear">
        <ion-icon slot="icon-only" :icon="caretBack"></ion-icon>
        {{ $t('Back') }}
    </ion-button>
</template>

<script setup lang="ts">
    import { confirmAlert } from '@/libs/userExperience'
    import { caretBack, close } from 'ionicons/icons';
    import { useI18n } from 'vue-i18n';
    import { useRouter } from 'vue-router';

    const i18n = useI18n();
    const router = useRouter();

    const props = defineProps({
        slot: {
            type: String,
            default: 'start'
        },
        haveChanges: {
            type: Boolean,
            default: false,
        },
        resetButtons: {
            type: Boolean,
            default: false
        }
    });

    const emit = defineEmits(['back','reset'])

    async function back(){
        let confirmExit = false;

        let buttons = [
            { text: i18n.t('Leave'), role: 'confirm', handler: () => { routerBack() } },
            { text: i18n.t('Cancel'), role: 'cancel' },
        ]
        if(props.resetButtons){
            buttons.splice(1, 0, 
                { text: i18n.t('Reset'), role: 'cancel', handler: () => { emit('reset') } }
            )
        }

        if(props.haveChanges){
            confirmExit = await confirmAlert({
                header: i18n.t('Changes are not saved'),
                message: i18n.t('Do you want to leave ?'),
                mode: 'ios',
                buttons: buttons
            });
        }
        else {
            routerBack()
        }
    }

    function routerBack(){
        emit('back')
        router.back()
    }

    
</script>

<style scoped>
</style>