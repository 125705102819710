import router from '@/router';
import { authAPI } from '@/libs/backend'
import { authTokenStore } from '@/stores/AuthTokenStore'


export async function login(username:string, password:string) {
    const tokenStore = authTokenStore();
    return authAPI.login(username, password).then(
        (data:any)=>{
            if(data && data.success == false){
                return data // login error reason
            }
            else if(data && data.access_token){
                tokenStore.store(data.access_token);
                router.push('/');
                return data
            }
            else{
                // TODO : user inteface actions ??
                console.error("Invalid token")
            }
        },
        (error:any)=>{
            // TODO : >= 400 Error seems to not go here :/
        }
    )
}

export function isLoggedIn(){
    const tokenStore = authTokenStore();
    return (tokenStore.token())
}

export async function logout() {
    const tokenStore = authTokenStore();
    authAPI.logout().then(
        ()=>{
            tokenStore.clear();
            router.push('/login');
        },
        (error:any)=>{
            // TODO : >= 400 Error seems to not go here :/
        }
    )
}