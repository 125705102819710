<template>
    <ion-page>
        <ion-content :fullscreen="true">
            <ion-row class="div-center">
                <ion-col sizeLg="4" offsetLg="4" sizeMd="6" offsetMd="3" sizeSm="12" offsetSm="0">
                    <ion-row>
                        <ion-col size="12" class="ion-text-center">
                            <img class="tripy-white-logo" src="/tripy_white.svg" />
                        </ion-col>
                    </ion-row>

                    <ion-row>
                        <ion-col size="12" class="ion-margin-top ion-text-center">
                            <ion-icon class="check-icon" color="success" :icon="checkmarkCircle"></ion-icon>
                            <h3>{{$t('You email has been validated')}}</h3>
                            <h3>{{$t('You will be redirected to login page')}}</h3>
                            <ion-spinner name="dots"></ion-spinner>
                        </ion-col>
                    </ion-row>

                    <ion-button router-link="/login" expand="block" fill="clear" shape="round" class="ion-margin-top">
                        {{$t('Back to Login')}}
                    </ion-button>
                    
                </ion-col>
            </ion-row>

        </ion-content>
    </ion-page>
</template>

<script setup lang="ts">
    import { onMounted } from 'vue';
    import { authAPI } from '@/libs/backend'
    import CheckMailbox from '@/components/CheckMailbox.vue';
    import { checkmarkCircle, mail } from 'ionicons/icons';
    import { useRoute, useRouter } from 'vue-router';
    import { presentToast } from '@/libs/userExperience';
    import { useI18n } from 'vue-i18n';

    const route = useRoute();
    const router = useRouter();
    const i18n = useI18n();
    const { email, token } = route.params;

    onMounted(()=>{
        authAPI.verifyAccount(token.toString()).then((data)=>{
            if(data && data.succes != false){
                setTimeout(() => {
                    router.push('/login')
                }, 4000);
            }
        });
    })
</script>

<style scoped>
    @import '@/theme/login.css';

    .check-icon {
        font-size: 3em;
    }
</style>