<template>
    <ion-item class="profile-select-item">
        <ion-icon slot="start" :src="'/profiles/'+routerProfile+'.svg'"></ion-icon>
        <ion-select :value="routerProfile" :placeholder="$t('Road Profile')" 
            fill="solid" mode="ios" interface="popover" justify="start" @ionChange="setWaypointProfile($event);dismiss($event);">
            <ion-select-option v-for="profile in roadbookStore.profiles" :value="profile.id">{{ profile.label }}</ion-select-option>
        </ion-select>
    </ion-item>
</template>

<script setup lang="ts">
    import { roadbookEditStore } from '@/stores/RoadbookEditStore';
    import { inject, ref } from 'vue';

    const roadbookStore = roadbookEditStore();
    const { coordinates, dismiss }:any = inject('popoverActions') 
    
    const routerProfile:any = ref(roadbookStore.getWaypointProfile(coordinates()));

    defineExpose({
        update,
    });

    function update(){
        routerProfile.value = roadbookStore.getWaypointProfile(coordinates());
    }

    function setWaypointProfile(e:any){
        routerProfile.value = e.detail.value
        roadbookStore.setWaypointProfile(routerProfile.value, coordinates())
    }
</script>

<style scoped>
    .profile-select-item {
        border: solid 2px #999; 
        border-radius: 10px
    }
</style>

