<template>
    <ion-list>
        <ion-item v-if="roadbookStore.isSectionDivisibleBefore(coordinates())" lines="none" button
            @click="roadbookStore.addWaypointBefore(coordinates());dismiss($event);">
            <ion-icon slot="start" src="/step-insert-before.svg"></ion-icon>
            <ion-label>{{ $t('Insert step before')}}</ion-label>
        </ion-item>
        <ion-item v-if="roadbookStore.isSectionDivisibleAfter(coordinates())" lines="none" button
            @click="roadbookStore.addWaypointAfter(coordinates());dismiss($event);">
            <ion-icon slot="start" src="/step-insert-after.svg"></ion-icon>
            <ion-label>{{ $t('Insert step after')}}</ion-label>
        </ion-item>
        <ion-item lines="none" @click="removeWaypoint" button>
            <ion-icon slot="start" :icon="trash" color="danger"></ion-icon>
            <ion-label>{{$t('Delete')}}</ion-label>
        </ion-item>
    </ion-list>
</template>

<script setup lang="ts">
    import { roadbookEditStore } from "@/stores/RoadbookEditStore";
    import { trash } from "ionicons/icons";
    import { inject } from "vue";

    const roadbookStore = roadbookEditStore();
    const { removeWaypoint, coordinates, dismiss }:any = inject('popoverActions') 
</script>

<style scoped>
</style>