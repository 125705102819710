<template>
    <ion-page>
        <ion-content :fullscreen="true">
            <ion-row class="div-center">
                <ion-col sizeLg="4" offsetLg="4" sizeMd="6" offsetMd="3" sizeSm="12" offsetSm="0">
                    <ion-row>
                        <ion-col size="12" class="ion-text-center">
                            <img class="tripy-white-logo" src="/tripy_white.svg" />
                        </ion-col>
                    </ion-row>

                    <ion-row v-if="!emailConfirmed">
                        <ion-col size="12" class="ion-text-center">
                            <h3>{{$t('Provide your account email to reset your password')}}</h3>
                        </ion-col>
                        <ion-col size="12" class="ion-margin-top">
                            <ion-input type="email" :placeholder="$t('Account email')" v-model="form.email" class="login-input">
                                <ion-icon slot="start" :icon="mail" size="large"></ion-icon>
                            </ion-input>
                        </ion-col>
                        <ion-col size="12" class="ion-margin-top">
                            <ion-button @click="sendRestorePasswordEmail" expand="block" color="tertiary" size="large" :disabled="!form.email || errorFields?.email?.length">
                                <b>{{$t('Send')}}</b>
                            </ion-button>
                        </ion-col>
                    </ion-row>

                    <CheckMailbox v-if="emailConfirmed" :email="form.email"></CheckMailbox>

                    <ion-button router-link="/login" expand="block" fill="clear" shape="round" class="ion-margin-top">
                        {{$t('Back to Login')}}
                    </ion-button>
                    
                </ion-col>
            </ion-row>

        </ion-content>
    </ion-page>
</template>

<script setup lang="ts">
    import { authAPI } from '@/libs/backend'
    import CheckMailbox from '@/components/CheckMailbox.vue';
    import { mail } from 'ionicons/icons';
    import { reactive, ref, onUpdated } from 'vue';
    import type { Rules } from 'async-validator';
    import { useAsyncValidator } from '@vueuse/integrations/useAsyncValidator';

    const emailConfirmed = ref(false)

    const form = reactive({ email: ''})
    const rules: Rules = {
        email: [
            {
                type: 'email',
                required: true,
            },
        ],
    }
    const { pass, isFinished, errorFields } = useAsyncValidator(form, rules)

    onUpdated(()=>{
        emailConfirmed.value = false;
    })
    
    async function sendRestorePasswordEmail(){
        authAPI.forgotPassword(form.email).then(()=>{
            emailConfirmed.value = true;
        });
    }
</script>

<style scoped>
    @import '@/theme/login.css';
</style>