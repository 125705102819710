import fr from '@/locales/fr.json'
import en from '@/locales/en.json'
// TODO
// import de from '@/locales/de.json'
// import nl from '@/locales/nl.json'

export const messages:any = {
    /* FRENCH */
    'fr': fr,
    'fr-DZ':fr,
    'fr-BE':fr,
    'fr-BJ':fr,
    'fr-BF':fr,
    'fr-BI':fr,
    'fr-CM':fr,
    'fr-CA':fr,
    'fr-CF':fr,
    'fr-TD':fr,
    'fr-KM':fr,
    'fr-CG':fr,
    'fr-CD':fr,
    'fr-CI':fr,
    'fr-DJ':fr,
    'fr-GQ':fr,
    'fr-FR':fr,
    'fr-GF':fr,
    'fr-PF':fr,
    'fr-GA':fr,
    'fr-GP':fr,
    'fr-GN':fr,
    'fr-HT':fr,
    'fr-LU':fr,
    'fr-MG':fr,
    'fr-ML':fr,
    'fr-MQ':fr,
    'fr-MR':fr,
    'fr-MU':fr,
    'fr-YT':fr,
    'fr-MA':fr,
    'fr-NC':fr,
    'fr-NE':fr,
    'fr-MC':fr,
    'fr-RE':fr,
    'fr-RW':fr,
    'fr-BL':fr,
    'fr-MF':fr,
    'fr-PM':fr,
    'fr-SN':fr,
    'fr-SC':fr,
    'fr-CH':fr,
    'fr-SY':fr,
    'fr-TG':fr,
    'fr-TN':fr,
    'fr-VU':fr,
    'fr-WF':fr,

    /* ENGLISH */
    'en': en,
    'en-AS':en,
    'en-AI':en,
    'en-AG':en,
    'en-AU':en,
    'en-AT':en,
    'en-BS':en,
    'en-BB':en,
    'en-BE':en,
    'en-BZ':en,
    'en-BM':en,
    'en-BW':en,
    'en-IO':en,
    'en-VG':en,
    'en-BI':en,
    'en-CM':en,
    'en-CA':en,
    'en-KY':en,
    'en-CX':en,
    'en-CC':en,
    'en-CK':en,
    'en-CY':en,
    'en-DK':en,
    'en-DM':en,
    'en-ER':en,
    'en-FK':en,
    'en-FI':en,
    'en-FJ':en,
    'en-GM':en,
    'en-DE':en,
    'en-GH':en,
    'en-GI':en,
    'en-GD':en,
    'en-GU':en,
    'en-GG':en,
    'en-GY':en,
    'en-HK':en,
    'en-IN':en,
    'en-IE':en,
    'en-IM':en,
    'en-IL':en,
    'en-JM':en,
    'en-JE':en,
    'en-KE':en,
    'en-KI':en,
    'en-LS':en,
    'en-LR':en,
    'en-MO':en,
    'en-MG':en,
    'en-MW':en,
    'en-MY':en,
    'en-MT':en,
    'en-MH':en,
    'en-MU':en,
    'en-FM':en,
    'en-MS':en,
    'en-NA':en,
    'en-NR':en,
    'en-NL':en,
    'en-NZ':en,
    'en-NG':en,
    'en-NU':en,
    'en-NF':en,
    'en-MP':en,
    'en-PK':en,
    'en-PW':en,
    'en-PG':en,
    'en-PN':en,
    'en-PR':en,
    'en-PH':en,
    'en-RW':en,
    'en-KN':en,
    'en-LC':en,
    'en-VC':en,
    'en-WS':en,
    'en-SC':en,
    'en-SL':en,
    'en-SG':en,
    'en-SX':en,
    'en-SI':en,
    'en-SB':en,
    'en-ZA':en,
    'en-SS':en,
    'en-SH':en,
    'en-SD':en,
    'en-SZ':en,
    'en-SE':en,
    'en-CH':en,
    'en-TZ':en,
    'en-TK':en,
    'en-TO':en,
    'en-TT':en,
    'en-TC':en,
    'en-TV':en,
    'en-UG':en,
    'en-AE':en,
    'en-GB':en,
    'en-US':en,
    'en-UM':en,
    'en-VI':en,
    'en-VU':en,
    'en-ZM':en,
    'en-ZW':en,

    /* GERMAN */
    // TODO

    // 'de':de,
    // 'de-AT':de,
    // 'de-BE':de,
    // 'de-DE':de,
    // 'de-IT':de,
    // 'de-LI':de,
    // 'de-LU':de,
    // 'de-CH':de,

    /* NETHERLAND */
    // TODO

    // 'nl':nl,
    // 'nl-AW':nl,
    // 'nl-BE':nl,
    // 'nl-BQ':nl,
    // 'nl-CW':nl,
    // 'nl-NL':nl,
    // 'nl-SX':nl,
    // 'nl-SR':nl,
}