<template>
    <ion-page>
        <ion-toolbar>
            <BackNavButton ref="exit" slot="start" @back="roadbookStore.$reset()"></BackNavButton>
            <InlineDocumentFieldEdit v-if-desktop :autoUpdate="!roadbookStore.isNew()"  @onUpdated="roadbookStore.value.title=$event"
                :documentAPI="roadbookAPI" :document="roadbookStore.value" :documentId="roadbookStore.id" 
                fieldName="title" placeholder="Add a title here">
            </InlineDocumentFieldEdit>
            <MapHistory ref="history" slot="end"></MapHistory>
            <MapEditHelpModal ref="userHelp" slot="end"></MapEditHelpModal>
            
            <MapSave ref="save" slot="end" :canSave="history && history.haveHistory()" @saving="loading = $event"></MapSave>
            
            <ion-progress-bar v-if="calculating || loading" type="indeterminate" color="primary"></ion-progress-bar>
        </ion-toolbar>
        <ion-content class="ion-no-padding">
            <ion-row class="ion-no-padding">
                <ion-col size="12" class="ion-no-padding">
                    <div style="height:100vh; width:100%">
                        <l-map ref="map" v-model:zoom="mapOptions.zoom" v-model:center="mapOptions.center" 
                            @click="setWaypoint($event.latlng)" @mousemove="setHeading($event.latlng)"
                            :useGlobalLeaflet="mapOptions.useGlobalLeaflet" :options="mapOptions.options" @contextmenu="openContextMenu">

                            <l-tile-layer :url="defaultMapLayer" layer-type="base" name="TripyMap3"></l-tile-layer>
 
                            <l-circle v-if="circle.visible" :lat-lng="circle.center" :radius="circle.radius" color="#3c9bab"></l-circle>
                            <l-polyline v-if="circle.visible" :lat-lngs="circleLineLatLng" color="#3c9bab"/>

                            <l-marker ref="contextMenu" :lat-lng="contextMenuLatLng" :options="{opacity: 0, width:'0px', height:'0px'}"> 
                            </l-marker>

                            <!-- @click="setWaypoint([roadbookStore.value.map_waypoints[0][0],roadbookStore.value.map_waypoints[0][1]])" -->
                            <l-marker v-if="roadbookStore.value.id && roadbookStore.value.map_waypoints.length" :lat-lng="roadbookStore.value.map_waypoints[0]"
                                :draggable="true" @moveend="moveStep($event)" 
                            >
                                <l-icon icon-url="/start.svg" :iconSize="[46, 60]" :iconAnchor="[23, 55]" :popupAnchor="[0,-43]"></l-icon>
                                <MapWaypointsPopover :latLon="roadbookStore.value.map_waypoints[0]" isStart :stepNumber="1"></MapWaypointsPopover>
                            </l-marker>
 
                            <l-polyline v-if="roadbookStore.value.points.length" :lat-lngs="roadbookStore.value.points" color="green" /> 
                        
                        </l-map>
                    </div>
                </ion-col>
            </ion-row>

            <MapEditHelpModal ref="mapHelpModal"></MapEditHelpModal>

            <MapSearchSheet ref="search" @place-selected="router.showPlace($event)"></MapSearchSheet>
            <MapLocateButton ref="locate" @focus="showCurrentLocation()"></MapLocateButton>
            
            <MapSettingsModal ref="mapSettingsModal"></MapSettingsModal>
    
            <ion-fab side="" vertical="bottom" class="random-fab">
                <ion-fab-button><ion-icon :icon="diceOutline" @click="randomSeed()&&calculateRoute()"></ion-icon></ion-fab-button>
            </ion-fab>
            <Router ref="router" :type="RoadbookType" @on-calculating-route="calculating = true" @on-route-calculated="calculating = false"></Router>

        </ion-content>
    </ion-page>

</template>

<script setup lang="ts">
    import { ref, onMounted, toRaw, watch } from 'vue';
    import { useRoute } from 'vue-router';
    import { diceOutline } from 'ionicons/icons';
    import InlineDocumentFieldEdit from '@/components/InlineDocumentFieldEdit.vue';
    import MapEditHelpModal from '@/components/MapEditHelpModal.vue';
    import { defaultMapLayer } from '@/libs/tileProviders'
    import BackNavButton from '@/components/BackNavButton.vue';
    import MapHistory from '@/components/MapHistory.vue';
    import MapSave from '@/components/MapSave.vue';
    import MapSettingsModal from '@/components/MapSettingsModal.vue';
    import MapSearchSheet from '@/components/MapSearchSheet.vue';
    import Router from '@/components/Router.vue';
    import MapLocateButton from '@/components/MapLocateButton.vue';
    import MapWaypointsPopover from '@/components/MapWaypointsPopover.vue';
    import { roadbookAPI } from '@/libs/backend';

    /* Leaflet */
    import "leaflet/dist/leaflet.css";
    import { LMap, LTileLayer, LMarker, LIcon, LCircle, LPolyline, LCircleMarker } from "@vue-leaflet/vue-leaflet";
    import { roadbookEditStore } from '@/stores/RoadbookEditStore';
    import { geoLocationStore } from '@/stores/geoLocationStore';
    
    // Data stores
    const roadbookStore = roadbookEditStore();
    const geoLocation = geoLocationStore();
    // variables refs
    const loading = ref(false)
    const calculating = ref(false)
    const mapOptions = ref({
      center: [ 49.67940329143511, 6.116638183593751 ],
      zoom: 12,
      useGlobalLeaflet: false,
      options: {zoomControl: false}
    });
    var userLocationFocused = false;

    // HTML components refs
    const map = ref();
    const contextMenu = ref();
    const mapHelpModal = ref();
    const locate = ref();
    const userHelp = ref();
    const mapSettingsModal = ref();
    const search = ref();
    const history = ref();
    const exit = ref();
    const save = ref();
    const router = ref();

    // variables refs
    const contextMenuLatLng = ref(mapOptions.value.center);
    const lines:any = ref([]);
    const circle:any = ref({
        visible: false,
        center: mapOptions.value.center,
        radius: undefined,
        radiusLatLng: mapOptions.value.center
    });
    const circleLineLatLng:any = ref([]);
    const RoadbookType ='discovery';

    const route = useRoute();
    const roadbookID:any = route.params.id || 'new';


    onMounted(()=>{
        // TODO get favorite map layer if present in user settings
        roadbookStore.init(roadbookID, RoadbookType);
        // roadbook.value = roadbookStore.value;
        focusUserLocation();
    });
    

    watch(() => roadbookStore.initialized, () => {
        focusUserLocation();
    });

    function focusUserLocation(){
        if(!userLocationFocused && geoLocation.location && roadbookStore.initialized && !roadbookStore.value.points.length) {
            setTimeout(()=>{ // let time to map initialization
                // map.value.showCurrentLocation();
                showCurrentLocation();
                userLocationFocused = true;
            },200)
        }
    }

    function focusLatLon(latlng:any){
        map.value.leafletObject.setView(latlng, 14)
    }

    function showCurrentLocation(){
        if(geoLocation.location) focusLatLon(geoLocation.location)
    }


    function computeAngle(latlngA:any, latlngB:any):number {
      let pointA = map.value.leafletObject.latLngToContainerPoint(latlngA);
      let pointB = map.value.leafletObject.latLngToContainerPoint(latlngB);
      let angleDeg:number = Math.atan2(pointB.y - pointA.y, pointB.x - pointA.x) * 180 / Math.PI + 90;
      if(angleDeg < 0) angleDeg = 360 + angleDeg;
      return Math.trunc(angleDeg % 360);
    }

    async function calculateRoute(){
        if(roadbookStore.value.map_waypoints.length) roadbookStore.value.map_waypoints = roadbookStore.value.map_waypoints;

        roadbookStore.value.points = await router.value.calculateRoundtrip(
            roadbookStore.value.map_waypoints, 
            roadbookStore.value.roundtrip_heading, 
            roadbookStore.value.roundtrip_distance, 
            roadbookStore.value.roundtrip_seed
        )

        circle.visible = false;
    }


    function openContextMenu(e:any){
        // TODO !!
        // contextMenuLatLng.value = [e.latlng.lat, e.latlng.lng]
        // contextMenu.value.leafletObject.openPopup();

        // contextMenu.value.$el.openPopup([e.latlng.lat, e.latlng.lng])
    }

    function setWaypoint(latlng:any){
        if(circle.value.visible){
            roadbookStore.value.roundtrip_heading = computeAngle(circleLineLatLng.value[0], circleLineLatLng.value[1]);
            roadbookStore.value.roundtrip_distance = Math.trunc(Number(circle.value.radius*5));
            calculateRoute()
            circle.value.visible = false;
            return
        }

        lines.value = [];
        roadbookStore.value.map_waypoints.pop();
        roadbookStore.value.map_waypoints.push([latlng.lat, latlng.lng]);
        contextMenu.value.leafletObject.closePopup();
        circle.value.center = [latlng.lat, latlng.lng];
        circle.value.visible = true;
    }
    
    function setHeading(latlng:any){
        if(circle.value.visible){
            let dist = map.value.leafletObject.distance(roadbookStore.value.map_waypoints[0], [latlng.lat, latlng.lng]);
            circle.value.radius = dist;
            circleLineLatLng.value = [toRaw(circle.value.center),[latlng.lat, latlng.lng]]
        }
    }

    function randomSeed(){
        // between 1 and 100
        let seed = roadbookStore.value.roundtrip_seed;
        while(seed == roadbookStore.value.roundtrip_seed){
            seed = Math.floor(Math.random() * 100 + 1);
        }
        roadbookStore.value.roundtrip_seed = seed;
        return seed;
    }

    function cancelActions(){
        circle.value.visible = false;
        roadbookStore.value.map_waypoints = []
    }

    function moveStep(e:any){
        console.log(e)
        console.log(e.target.getLatLng())
        let latlon = e.target.getLatLng()
        console.log([latlon.lat, latlon.lng])
        roadbookStore.value.map_waypoints[0] = [latlon.lat, latlon.lng]
        calculateRoute()
    }
</script>

<style scoped>
    ion-toolbar ion-title:hover {
        cursor: pointer;
    }

    ion-content {
        height: 100vh;
        width: 100vw;
    }

    ion-button.btn-icon-xl {
        width: 70px !important;
        height: 70px !important;
        font-size: 70px !important;
    }

    ion-button.btn-icon-md {
        width: 55px !important;
        height: 55px !important;
        font-size: 55px !important;
    }

    .leaflet-container{
        /* cursor: pointer !important; */
    }

    .random-fab {
        right: 80px;
    }
</style>

