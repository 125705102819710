<template>
    <ion-header>
          <ion-toolbar v-if="showLogo">
            <!-- <ion-grid fixed>
              <ion-row>
                <ion-col size="1">
                  <ion-avatar class="ion-margin-end">
                    <img alt="Tripy logo" src="/tripy_white.svg"/>
                  </ion-avatar>
                </ion-col>
                <ion-col>
                  <ion-text>
                    <h1><b><AppName bold size="35px"></AppName></b></h1>
                    <p class="punchline">The route !</p>
                  </ion-text>
                  <ion-buttons slot="end">
                    <ion-menu-toggle>
                      <ion-button fill="clear">
                        <ion-icon slot="icon-only" :icon="close"></ion-icon>
                      </ion-button>
                    </ion-menu-toggle>
                  </ion-buttons>
                  
                </ion-col>
              </ion-row>
            </ion-grid> -->
            <img id="menu-logo" src="/tripymap_the_route.svg" alt=""/>
          </ion-toolbar>
        </ion-header>
        <ion-content>
          <ion-list>
            <!-- <ion-menu-toggle :auto-hide="false" v-for="(p) in appPages"> -->
            <div v-for="(p) in appPages">
              <ion-item  button @click="pageSelected = p.url" :router-link="p.url" v-if="isDesktop || (isMobile && !p.hideMobile)" lines="none" :class="{ selected: pageSelected === p.url}">
                <ion-icon aria-hidden="true" slot="start" :icon="p.icon" color="tertiary"></ion-icon>
                <ion-label>{{ p.title }}</ion-label>
              </ion-item>
            </div>
            <!-- </ion-menu-toggle> -->
            <!-- <ion-menu-toggle :auto-hide="false" v-for="(p) in footerPages"> -->
                <ion-item v-for="(p) in footerPages" button @click="pageSelected = p.url" :router-link="p.url" lines="none" :class="{ selected: pageSelected === p.url }">
                  <ion-icon aria-hidden="true" slot="start" :icon="p.icon" color="tertiary"></ion-icon>
                  <ion-label>{{ p.title }}</ion-label>
                </ion-item>
            <!-- </ion-menu-toggle> -->
    
            <!-- <ion-menu-toggle :auto-hide="false"> -->
              <ion-item lines="none" button router-link="/user/account">
                <ion-icon slot="start" :icon="personCircleOutline" color="tertiary"></ion-icon>
                <ion-label>{{$t('Account')}}</ion-label>
                <ion-buttons slot="end">
                  <ion-button @click="userLogout($event)" color="primary">
                    <ion-icon slot="icon-only" color="tertiary" :icon="powerOutline"></ion-icon>
                  </ion-button>
                </ion-buttons>
              </ion-item>
            <!-- </ion-menu-toggle> -->
          </ion-list>
        </ion-content>
        <ion-footer>
        </ion-footer>
</template>

<script setup lang="ts">
    import { cogOutline, close, personCircleOutline, exitOutline, navigateCircleOutline, gridOutline, hardwareChipOutline, mapOutline, peopleCircleOutline, powerOutline } from 'ionicons/icons';
    import AppName from '@/components/AppName.vue';
    import { logout } from '@/services/authService'
    import { ref, inject } from 'vue';
    import { useI18n } from "vue-i18n";
    import { useRouter } from 'vue-router';

    const pageSelected = ref(useRouter().currentRoute.value.path);
    const i18n = useI18n();
    const isMobile = inject('isMobile')
    const isDesktop = inject('isDesktop')

    defineProps({
        showLogo: {
            type: Boolean,
            default: true
        },
    });

    const appPages = [
      {
          title: i18n.t('Dashboard'),
          url: '/dashboard',
          icon: gridOutline,
          hideMobile: true
      },
      {
          title: i18n.t('Community'),
          url: '/community',
          icon: peopleCircleOutline
      },
      {
          title: i18n.t('RoadBooks'),
          url: '/roadbooks',
          icon: navigateCircleOutline
      },
      {
          title: i18n.t('Devices'),
          url: '/devices',
          icon: hardwareChipOutline
      },
    ];

    const footerPages = [
      {
          title: i18n.t('Parameters'),
          url: '/Parameters',
          icon: cogOutline
      }
    ]

    function userLogout(e: Event){
        e.preventDefault()
        e.stopPropagation()
        logout();
    }
</script>