<template>
    <ion-fab ref="profileFAB" slot="fixed" horizontal="start" vertical="top">
        <Popper arrow :show="showPopper" placement="right" :content="$t('Change your road style here')">
            <ion-fab-button class="profile-btn" color="petrol">
                <ion-icon :src="'/profiles/'+roadbookStore.currentProfile()+'.svg'" class="profile-icon" color="light"></ion-icon>
            </ion-fab-button>
        </Popper>
        <ion-fab-list side="bottom">
            <ion-card v-for="p of roadbookStore.profiles" button class="ion-no-margin fab-card" @click="selectProfile(p)">
                <ion-card-header class="ion-no-padding">
                    <ion-item lines="none" class="profile-selection">
                        <ion-icon :src="'/profiles/'+p.id+'.svg'"></ion-icon>
                        <ion-label class="ion-margin-start" :color="roadbookStore.currentProfile() == p.id ? 'primary':'light'">{{p.label}}</ion-label>
                        <ion-buttons slot="end">
                            <ion-button slot="end" @click="profileInfo  ($event, p)">
                                <ion-icon slot="icon-only" :icon="informationCircle" color="tertiary"></ion-icon>
                            </ion-button>
                        </ion-buttons>
                    </ion-item>
                </ion-card-header>
                <ion-card-content v-if="revealDescription[p.id]">
                    {{ p.description }}
                </ion-card-content>
            </ion-card>

            <ion-card button class="ion-no-margin fab-card">
                <ion-card-content>
                    <ion-item>
                        <ion-toggle>{{ $t('Highway') }}</ion-toggle>
                    </ion-item>
                    <ion-item>
                        <ion-toggle>{{ $t('Tollgate') }}</ion-toggle>

                    </ion-item>
                </ion-card-content>
            </ion-card>
        </ion-fab-list>
    </ion-fab>
</template>

<script setup lang="ts">
    import { presentToast } from '@/libs/userExperience';
    import { roadbookEditStore } from '@/stores/RoadbookEditStore';
    import { informationCircle } from 'ionicons/icons';
    import { ref } from 'vue';
    import { useI18n } from 'vue-i18n';

    const roadbookStore = roadbookEditStore();

    // HTML Element refs
    const profileFAB = ref();
    // Variable refs
    const i18n = useI18n();
    const revealDescription:any = ref({});
    const showPopper = ref(false);

    defineExpose({
        showUserTip
    });

    function selectProfile(p:any){
        roadbookStore.setCurrentProfile(p.id);
        profileFAB.value.$el.close();
        presentToast(i18n.t('Selected profile')+': '+p.label, 'dark')
    }

    function profileInfo(e:Event, p:any){
        e.stopPropagation()
        if(!revealDescription.value[p.id])
            revealDescription.value[p.id] = true
        else
            revealDescription.value[p.id] = false
    }

    function showUserTip(){
        showPopper.value = true;
        setTimeout(() => {
            showPopper.value = false;
        }, 3000);
    }
</script>

<style scoped>
.profile-btn{
    --border-width: 3px;
    --border-style: solid;
    --border-color: var(--ion-color-petrol);
}

.profile-btn button .close-icon {
    color: #fff !important;
    --color: #fff !important;
    --background: #fff !important;
    background: #fff !important;
    background-color: #fff !important;
    --background-color: #fff !important;
    font-size: 50px !important;
    --ion-color-base: white !important;
    --background-focused: white !important;
    --color-focused: white !important;
    --close-icon-font-size: white !important;
}
.profile-icon {
    font-size: 45px !important;
}

.backdrop-hide {
  display:none;
}

.fab-item, .fab-card{
    left: 0px;
    width: 300px;
    max-width: 300px;
    min-width: 300px;
    border-radius: 10px;
    margin-top: 5px;
}

.profile-selection {
    font-size: 20px;
}

.fab-button-in-list{
    width: 60px;
    height: 60px;
}
.fab-button-in-list ion-icon {
    font-size: 50px;
}

</style>