import { Device } from '@capacitor/device';


export async function setupDeviceDirectives(app:any){
    /* Add device v- directive */
    const device = await Device.getInfo();
    
    /* Usage : 
      <tag v-if-mobile></tag>
      <tag v-if-mobile:hide></tag>
    */
    app.directive('if-mobile', async(el:any, binding:any, vnode:any)=> {
      let show = false;
      if(isMobile()){
        show = true;
        if (binding.arg == 'hide') show = false;
      } 
        
      if(!show){
        vnode.el.hidden = true;
        el.style.display = 'none';
      }
    });
    function isMobile(){
      return (device.platform == 'ios' || device.platform == 'android' || 
      device.operatingSystem == 'ios' || device.operatingSystem == 'android')
    }
    
    /* Usage : 
      <tag v-if-desktop></tag>
      <tag v-if-desktop:hide></tag>
    */
    app.directive('if-desktop', async(el:any, binding:any, vnode:any)=> {
      let show = false;
      if(isDesktop()) {
        show = true;
        if (binding.arg == 'hide') show = false;
      }
      if(!show){
        vnode.el.hidden = true;
        el.style.display = 'none';
      }
    });
    function isDesktop(){
      return (device.platform == 'web' && device.operatingSystem != 'ios' && device.operatingSystem != 'android');
    }
    
    app.provide('userDevice', device)
    app.provide('isMobile', isMobile())
    app.provide('isDesktop', isDesktop())
}