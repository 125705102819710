<template>
        <ion-content id="dashboard" :fullscreen="true">
            <img id="menu-logo" src="/tripymap_the_route.svg" alt="tripymap the route" class="ion-padding"/>
            <ion-row>
                <ion-col size="6">
                    <TutoButton></TutoButton>
                </ion-col>

                <ion-col size="12" class="ion-text-center">
                    <ion-text>
                        <h2 class="strong">{{ $t("Start your") }}<span><i><b>TRIP</b></i></span></h2>
                    </ion-text>
                </ion-col>

                <ion-col size="12">
                    <ion-item button mode="md" lines="none" class="trip-button ion-margin-bottom" @click="createRoadbook('flash')">
                        <MaterialSymbol name="bolt"></MaterialSymbol>
                        <ion-label slot="end">{{$t('FLASH TRIP')}}</ion-label>
                    </ion-item>
                    <ion-item button mode="md" lines="none" class="trip-button ion-margin-bottom" @click="createRoadbook('discovery')">
                        <MaterialSymbol name="laps" grade="bold"></MaterialSymbol>
                        <ion-label slot="end">{{$t('BOOMERANG')}}</ion-label>
                    </ion-item>
                    <ion-item button mode="md" lines="none" class="trip-button ion-margin-bottom" @click="createRoadbook('routing')">
                        <MaterialSymbol name="stylus_note"></MaterialSymbol>
                        <ion-label slot="end">{{$t('CREATE A TRIP')}}</ion-label>
                    </ion-item>
                </ion-col>

                <ion-col size="12">
                    <ion-item button class="menu-button" lines="none"  router-link="/roadbooks">
                        <ion-label color="primary">{{$t('My Roadbooks')}}</ion-label>
                        <MaterialSymbol slot="end" name="menu" color="primary"></MaterialSymbol>
                    </ion-item>
                    <ion-item button class="menu-button" lines="none"  router-link="/community">
                        <ion-label color="primary">{{$t('Community')}}</ion-label>
                        <MaterialSymbol slot="end" name="public" color="primary"></MaterialSymbol>
                    </ion-item>
                    <ion-item button class="menu-button" lines="none"  router-link="/devices">
                        <ion-label>{{$t('Devices')}}</ion-label>
                        <MaterialSymbol slot="end" name="assistant_navigation"></MaterialSymbol>
                    </ion-item>
                    <ion-item button class="menu-button" lines="none"  router-link="/parameters">
                        <ion-label>{{$t('Settings')}}</ion-label>
                        <MaterialSymbol slot="end" name="settings"></MaterialSymbol>
                    </ion-item>
                    <ion-item button class="menu-button" lines="none"  router-link="/user/account">
                        <ion-label>{{$t('Account')}}</ion-label>
                        <MaterialSymbol slot="end" name="account_circle"></MaterialSymbol>
                    </ion-item>
                </ion-col>

            </ion-row>
        </ion-content>

</template>

<script setup lang="ts">
  import { earth, hardwareChipOutline, menu, cogOutline, personCircleOutline, createOutline } from 'ionicons/icons';
  import TutoButton from '@/components/TutoButton.vue';
  import { useRouter } from 'vue-router';
  import { MaterialSymbol } from '@dbetka/vue-material-symbols';

  const router = useRouter();

  function createRoadbook(type:string){
    router.push({path: `/roadbooks/new/edit/${type}`})
  }

</script>


<style scoped>
    .trip-button {
        border-radius: 10px;
        border: 2px var(--ion-color-light) solid;
        font-size: 25px;
    }

    .menu-button {
        border-radius: 10px;
        --background: none;
    }
</style>