<template>
    <ion-page>
        <ion-header>
            <ion-toolbar>
                <!-- <ion-buttons slot="start" color="primary">
                    <ion-menu-toggle>
                        <ion-button fill="clear" shape="round" color="light" size="large">
                        <ion-icon slot="icon-only" :icon="menu"></ion-icon>
                        </ion-button>
                    </ion-menu-toggle>
                </ion-buttons> -->
                <BackNavButton></BackNavButton>
                <ion-title>{{ $t("Devices") }}</ion-title>
                <ion-button v-if-desktop slot="end" @click="createDeviceModal.open()" shape="round" size="large" class="bold">
                    <ion-icon ref="createButton" slot="start" :icon="addCircleOutline"></ion-icon>
                    {{$t('Add')}}
                </ion-button>
            </ion-toolbar>
        </ion-header>
        <ion-content>
            <ion-button v-if-mobile @click="createDeviceModal.open()" expand="full" size="large" class="bold">
                    <ion-icon ref="createButton" slot="start" :icon="addCircleOutline"></ion-icon>
                    {{$t('Add')}}
            </ion-button>
            <ion-grid v-if-desktop>
                <ion-row>
                    <ion-col sizeLg='2'><ion-text class="bold ion-padding-start">{{$t('Model')}}</ion-text></ion-col>
                    <ion-col sizeLg='3'><ion-text class="bold ion-padding-start">{{$t('Label')}}</ion-text></ion-col>
                    <ion-col sizeLg='3'><ion-text class="bold">{{$t('Serial Number')}}</ion-text></ion-col>
                    <ion-col sizeLg='3'></ion-col>
                </ion-row>
            </ion-grid>
                <ion-card v-for="device in devices">
                    <ion-grid>
                    <ion-row>
                        <ion-col sizeLg='2' size="12">
                            <ion-chip color="primary">
                                <ion-text>{{device.model}}</ion-text>
                            </ion-chip>
                        </ion-col>
                        <ion-col sizeLg='3' size="12">
                            <InlineDocumentFieldEdit :documentAPI="deviceAPI" :document="device" :documentId="device.serial_number" fieldName="label" placeholder="Set a label">{{device.text}}</InlineDocumentFieldEdit>
                        </ion-col>
                        <ion-col sizeLg='3' size="12"><ion-text><h4>{{device.serial_number}}</h4></ion-text></ion-col>
                        <ion-col sizeLg='3' size="12" class="">
                            <ion-button @click="remove(device.serial_number)" color="danger">
                                <ion-icon slot="start" :icon="trashBinOutline"></ion-icon>
                                <ion-label>{{$t('Remove')}}</ion-label>
                            </ion-button>
                        </ion-col>
                    </ion-row>
                    </ion-grid>
                </ion-card>
        </ion-content>
        <ion-footer>
            <ion-toolbar>
                <ion-button href="https://tripy.be" target="_blank" expand="block" fill="clear" shape="round" color="light">
                    <ion-icon slot="start" :icon="cartOutline"></ion-icon>
                    Buy a Tripy GPS
                </ion-button>
            </ion-toolbar>
        </ion-footer>

        <CreateDeviceModal ref="createDeviceModal" @created="fetchDevices" ></CreateDeviceModal>
    </ion-page>
</template>

<script setup lang="ts">
    import { ref, onMounted, onUpdated } from 'vue'
    import { refreshData } from '@/libs/userExperience'
    import { addCircleOutline, caretBack, cartOutline, menu, trashBinOutline } from 'ionicons/icons';
    import { deviceAPI } from '@/libs/backend';
    import CreateDeviceModal from '@/components/CreateDeviceModal.vue';
    import { confirmAlert } from '@/libs/userExperience';
    import { useI18n } from 'vue-i18n';
    import { useRouter } from 'vue-router';
    import InlineDocumentFieldEdit from '@/components/InlineDocumentFieldEdit.vue';
    import { confirmDeletion } from '@/libs/userExperience'
    import BackNavButton from '@/components/BackNavButton.vue';

    const devices:any = ref([])
    const createDeviceModal = ref()
    const router = useRouter();

    onUpdated(()=>{
        refreshData(fetchDevices)
    });

    onMounted(()=>{
        fetchDevices()
    });

    async function fetchDevices(){
        devices.value = await deviceAPI.all();
    }

    async function remove(serialNumber:string) {
        if(await confirmDeletion()){
            await deviceAPI.delete(serialNumber);
            fetchDevices()
        }
    }
</script>

<style scoped>
</style>