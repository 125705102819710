import jq from 'jqts';

export const maxWaypoints = 300;

export function switchLatLng(latLngList:any[]):any[]{
    /* for simple [lat,lng] */
    if(latLngList.length && typeof(latLngList[0]) == 'number'){
        return [latLngList[1], latLngList[0]]
    }

    /* for list of latlng [[lat,lng],[lat,lng],[lat,lng]] */
    let switchedList:any[] = []
    for(let el of latLngList){
        switchedList.push([el[1], el[0]])
    }
    return switchedList;
}

export function extractLineStrings(jqCompiler:jq, routerResponse:any){
    try{
        return switchLatLng(jqCompiler.evaluate(routerResponse))
    }
    catch{
        return [];
    }
}

export function isSameWaypoints(wp1:any, wp2:any){
    return wp1.length >= 2 && wp2.length >= 2 
        && JSON.stringify([wp1[0],wp1[1]]) === JSON.stringify([wp2[0],wp2[1]])
}

export function isNotSameWaypoints(wp1:any, wp2:any){
    return wp1.length >= 2 && wp2.length >= 2 
        && JSON.stringify([wp1[0],wp1[1]]) !== JSON.stringify([wp2[0],wp2[1]])
}