
<template>
    <ion-card :button="button" @click="goHref">
        <div v-if="routing">
            <ion-card-header>
                <ion-card-title>{{ $t("Routes") }}</ion-card-title>
                <ion-card-subtitle v-if="punchline">{{ $t("Create your routes as you feel") }}</ion-card-subtitle>
            </ion-card-header>
            <ion-card-content>
                <img src="/routing.png"/>
            </ion-card-content>
        </div>

        <div v-if="discovery">
            <ion-card-header>
                <ion-card-title>{{ $t("Discovery") }}</ion-card-title>
                <ion-card-subtitle v-if="punchline">{{ $t("Let your instinct guide you") }}</ion-card-subtitle>
            </ion-card-header>
            <ion-card-content>
                <img src="/discover.png"/>
            </ion-card-content>
        </div>
    </ion-card>
</template>

<script setup lang="ts">
    import { useRouter } from 'vue-router';

    const props = defineProps({
        href: {
            type: String,
            required: false,
            default:null
        },
        routing: {
            type: Boolean,
            default:false,
            required: false,
        },
        discovery: {
            type: Boolean,
            default:false,
            required: false,
        },
        punchline: {
            type: Boolean,
            required: false,
            default: true
        },
        button: {
            type: Boolean,
            required: false,
            default: true
        }
    });

    const router = useRouter()

    function goHref(){
        if(props.href){
            router.push(props.href)
        }
    }
</script>

<style scoped>

</style>

