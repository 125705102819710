<template>
    <ion-buttons :slot="btnSlot" :class="btnClass">
        <ion-button id="filter-popover" shape="rounded" size="medium">
            <ion-icon slot="start" :icon="funnel"></ion-icon>
            {{$t('Filters')}}
        </ion-button>
    </ion-buttons>
    <ion-popover trigger="filter-popover" :dismiss-on-select="true">
        <ion-content>
            <ion-list>
                <ion-item lines="none" button @click="sortBy('title')">
                    <ion-icon :icon="textOutline"></ion-icon><ion-label class="ion-margin">{{ $t('Name A-Z')}}</ion-label>
                </ion-item>
                <ion-item lines="none" button @click="sortBy('title', 'desc')">
                    <ion-icon :icon="textOutline"></ion-icon><ion-label class="ion-margin">{{ $t('Name Z-A')}}</ion-label>
                </ion-item>
                <ion-item lines="none" button @click="sortBy('updated_date', 'desc')">
                    <ion-icon :icon="timeOutline"></ion-icon><ion-label class="ion-margin">{{ $t('Newest')}}</ion-label>
                </ion-item>
                <ion-item lines="none" button @click="sortBy('updated_date')">
                    <ion-icon :icon="timeOutline"></ion-icon><ion-label class="ion-margin">{{ $t('Oldest')}}</ion-label>
                </ion-item>
                <ion-item lines="none" button @click="filterBy('favorite', true)">
                    <ion-icon :icon="heart"></ion-icon><ion-label class="ion-margin">{{ $t('Favorite')}}</ion-label>
                </ion-item>
                <ion-item lines="none" button @click="filterBy('type', 'discovery')">
                    <ion-icon :icon="trailSignOutline"></ion-icon><ion-label class="ion-margin">{{ $t('discovery')}}</ion-label>
                </ion-item>
                <ion-item lines="none" button @click="filterBy('type', 'routing')">
                    <ion-icon :icon="flagOutline"></ion-icon><ion-label class="ion-margin">{{ $t('routing')}}</ion-label>
                </ion-item>
            </ion-list>
        </ion-content>
    </ion-popover>
</template>

<script setup lang="ts">
import { flagOutline, funnel, heart, textOutline, timeOutline, trailSignOutline } from 'ionicons/icons';
import { onMounted } from 'vue';

    var sortOptions:any = {field:'title', action:'asc'}
    var filteredRoadbooks:any = []

    defineExpose({
        sort,
        sortBy,
        filterBy,
    });

    const props = defineProps({
        roadbooks: {
            type: Object,
            required: true
        },
        filteredRoadbooks: {
            type: Object,
            required: true
        },
        btnClass: {
            type: String,
            required: false
        },
        btnSlot: {
            type: String,
            required: false
        }
    });

    const emit = defineEmits(['update:filteredRoadbooks'])

    onMounted(()=>{
        if(props.roadbooks.length){
            sort();
        }
    });

    function tryLower(str:string){
        if(typeof(str) == "string") return str.toLowerCase();
        return str;
    }

    function sort(){
        sortBy(sortOptions.field, sortOptions.action);
    }

    function sortBy(field:string, action:string='asc'){
        sortOptions = {field:field, action:action}
        filteredRoadbooks = props.roadbooks;
        filteredRoadbooks.sort(function(first:any, second:any) {
            if(action == 'bool'){
                return second[field] == true && second[field] > first[field];
            }
            else if(action == 'desc'){
                return tryLower(second[field]) > tryLower(first[field]);
            }
            else if(action == 'asc') {
                return tryLower(second[field]) < tryLower(first[field]);
            }
        });
        emit("update:filteredRoadbooks", filteredRoadbooks)
    }

    function filterBy(field:string, value:any){
        filteredRoadbooks = props.roadbooks;
        filteredRoadbooks = filteredRoadbooks.filter((v:any)=>v[field]==value);
        emit("update:filteredRoadbooks", filteredRoadbooks)
    }
</script>

<style scoped>

</style>