<template>
    <ion-item class="ion-no-padding-end ion-no-margin ion-no-padding" lines="none">
        <ion-button slot="start" @click="copyLatLng" class="ion-no-margin" :color="copied?'success':''">
            {{(copied? $t('Copied'):'LatLon')}}
            <ion-icon slot="end" :icon="copied?checkmarkSharp:copyOutline" ></ion-icon>
        </ion-button>
        <ion-buttons slot="end">
            <ion-button slot="end" @click="toggleFavorite">
                <ion-icon slot="icon-only" :icon="isFavorite ? star:starOutline" :color="isFavorite ? 'tertiary':''"></ion-icon>
            </ion-button>
            <ion-button slot="end" @click="dismiss">
                <ion-icon slot="icon-only" :icon="close" size="large"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-item>

    <CreateFavoritePlaceModal ref="createFavoritePlaceModal" @created="updateFavorite"></CreateFavoritePlaceModal>
</template>

<script setup lang="ts">
    import { useClipboard } from '@vueuse/core'
    import { useI18n } from "vue-i18n";
    import { presentToast, } from '@/libs/userExperience';
    import { checkmarkSharp, close, copyOutline, star, starOutline } from 'ionicons/icons';
    import { ref, inject } from 'vue'
    import CreateFavoritePlaceModal from '@/components/CreateFavoritePlaceModal.vue';
    import { favoritePlaceStore } from '@/stores/FavoritePlaceStore'
    import { confirmDeletion } from '@/libs/userExperience';

    const i18n = useI18n();
    const { text, copy, copied, isSupported: copyIsSupported } = useClipboard()
    const { dismiss, coordinates }:any = inject('popoverActions') 
    
    const favoritePlaces = favoritePlaceStore();
    const createFavoritePlaceModal = ref()
    const isFavorite = ref(checkFavorite())

    const emit = defineEmits([
        'dismiss'
    ]);

    async function copyLatLng(){
        if(copyIsSupported){
            await copy(coordinates().toString())
        }
        else{
            presentToast(i18n.t('Copy function is not supported by this browser'), 'warning')
        }
    }

    async function toggleFavorite(){
        if(favoritePlaces.processing) return
        if(!isFavorite.value){ // create
            createFavoritePlaceModal.value.open(coordinates())
        }
        else{ // remove
            if(await confirmDeletion()){
                await favoritePlaces.remove(coordinates())
                isFavorite.value = checkFavorite()
            }
        }
    }

    async function updateFavorite(){
        isFavorite.value = checkFavorite()
    }

    function checkFavorite(){
        return favoritePlaces.isFavoritePlace(coordinates())
    }

</script>

<style scoped>
</style>

