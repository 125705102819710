<template>
    <ion-buttons v-if="buttons" slot="end">
        <ion-button @click="undo" :disabled="!canUndo()">
            <ion-icon slot="icon-only" :icon="arrowUndoOutline" size="large"></ion-icon>
        </ion-button>
        <ion-button @click="redo" :disabled="!canRedo()">
            <ion-icon slot="icon-only" :icon="arrowRedoOutline" size="large"></ion-icon>
        </ion-button>
    </ion-buttons>
</template>

<script setup lang="ts">
    import { roadbookEditStore } from '@/stores/RoadbookEditStore';
    import { arrowRedoOutline, arrowUndoOutline } from 'ionicons/icons';
    import { computed } from 'vue';
    import { maxWaypoints } from "@/libs/geoUtils";
    import { useRefHistory } from '@vueuse/core';

    const roadbookStore = roadbookEditStore();
    var historyStart = -1;

    defineProps({
        buttons: {
            type: Boolean,
            default: true
        },
        slot: {
            type: String,
            default: 'start'
        }
    });

    defineExpose({
        undo,
        redo,
        canUndo,
        canRedo,
        haveHistory
    });

    // Trick with computed references to have several variable usefd by history process
    const computedHistory = computed({
        get() {
            return {
                map_waypoints: roadbookStore.value.map_waypoints,
                roundtrip: roadbookStore.value.roundtrip
            }
        },
        set(setValue:any) {
            roadbookStore.value.map_waypoints = setValue.map_waypoints;
            roadbookStore.value.roundtrip = setValue.roundtrip;
        }
    })

    const { history, undo: histoUndo, redo: histoRedo, canRedo: histoCanRedo } = useRefHistory(computedHistory, {capacity: maxWaypoints,deep:true})


    // Set history start only when roadbook Store is fully initialized
    roadbookStore.$subscribe(async(mutation:any, state) => {
        if(roadbookStore.initialized && historyStart == -1){
            historyStart = history.value.length;
        }
    })

    function canUndo(){
        if(!historyStart) return false
        else return (history.value.length && historyStart >= 0 && history.value.length > historyStart)
    }

    function canRedo(){
        return histoCanRedo.value;
    }

    function undo(){
        if(canUndo()) histoUndo();
    }

    function redo(){
        if(canRedo()) histoRedo();
    }

    function haveHistory(){
        return historyStart != -1 && history.value.length > historyStart
    }
    
</script>

<style scoped>
</style>