<template>
    <ion-modal ref="createModal" v-on:keyup.escape="dismiss" v-on:keyup.enter="submit" :can-dismiss="true">
        <ion-header>
            <ion-toolbar>
                <ion-title class="ion-margin-start">
                    {{$t('New roadbook')}}
                    <ion-chip v-if="type" color="tertiary" mode="ios" outline="true">
                        <ion-label>{{$t(type)}}</ion-label>
                    </ion-chip>
                </ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-card-content>
            <ion-row>
                <ion-col v-if="showTitle || copyData" size="12">
                    <ion-input fill="outline" v-model="roadbookTitle" ref="roadbookTitleInput" required="true" label-placement="floating" :label="$t('RoadbookName')" type="text" :error-text="$t('Required value')"></ion-input>
                </ion-col>
                <ion-col size="6" v-if="!hideTypes">
                    <RoadbookType discovery @click="changeType('discovery')" :class="roadbookType == 'discovery' ? 'card-selected':''"></RoadbookType>
                </ion-col>
                <ion-col size="6" v-if="!hideTypes">
                    <roadbookType routing @click="changeType('routing')" :class="roadbookType == 'routing' ? 'card-selected':''"></RoadbookType>
                </ion-col>
            </ion-row>
        </ion-card-content>
        <ion-toolbar class="footer ion-padding-end">
            <ion-button slot="end" expand="full" @click="dismiss" fill="clear">{{$t('Discard')}}</ion-button>
            <ion-button slot="end" expand="full" @click="submit($event)" fill="clear" :disabled="!roadbookTitle">{{$t('OK')}}</ion-button>
        </ion-toolbar>
    </ion-modal>
</template>

<script setup lang="ts">
    import { ref } from 'vue';
    import { showIonInputError, focusInput } from '@/libs/userExperience.js'
    import { roadbookAPI } from '@/libs/backend';
    import RoadbookType from '@/components/RoadbookType.vue';
    import { closeOutline } from 'ionicons/icons';

    // Component event emission
    const emit = defineEmits<{
        (e: 'onDismiss'): void
        (e: 'onCreated', data:any): void
    }>();

    defineExpose({
        dismiss,
        open,
        openCopy
    });

    var props = defineProps({
        type: {
            type: String,
            required: false,
            default: undefined
        },
        hideTypes: {
            type: Boolean,
            required: false,
            default: false
        },
        showTitle: {
            type: Boolean,
            required: false,
            default: false
        }
    });

    // HTML Element refs
    const createModal = ref();
    const roadbookTitleInput = ref();

    // Variable refs
    const roadbookTitle = ref('');
    const roadbookType = ref('discovery');
    const modalHeight = ref(props.hideTypes? '300px': '600px')
    var copyData:any = null;

    function submit(e:Event){
        e.stopPropagation();
        
        let error:boolean = false;
        // validation
        error = showIonInputError(roadbookTitleInput, (props.showTitle && !roadbookTitle.value)) || error

        if(error) return;
        
        let data:any = {}
        if(copyData){
            data = copyData;
        }
        data.title = roadbookTitle.value;
        data.type = props.type ? props.type : roadbookType.value;
        
        roadbookAPI.create(data).then((res)=>{
            emit('onCreated', res);
            dismiss(); 
        })
    }

    function resetFields(){
        copyData = undefined;
        roadbookTitle.value = '';
    }

    async function open(){
        resetFields();
        roadbookTitle.value = await roadbookAPI.generateTitle(roadbookType.value);
        createModal.value.$el.present();
        if(props.showTitle) focusInput(roadbookTitleInput)
    }

    function openCopy(data:any=null){
        open();
        if(data && data.title){
            copyData = data;
            roadbookTitle.value = data.title;
        }
        else{
            resetFields();
        }
        focusInput(roadbookTitleInput);
    }

    async function changeType(type:string){
        roadbookType.value = type;
        roadbookTitle.value = await roadbookAPI.generateTitle(type);
    }

    function dismiss(){
        createModal.value.$el.dismiss();
        emit('onDismiss');
    }

</script>

<style scoped>
ion-modal {
    --height: v-bind(modalHeight);
    --max-height: 700px;
}
.card-selected{
    border: 1px solid var(--ion-color-primary)
}
</style>