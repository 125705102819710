import { defineStore } from 'pinia'
import { favoriteAPI } from '@/libs/backend'
import { defaultMapLayer } from '@/libs/tileProviders'
import { useI18n } from "vue-i18n";
import { presentToast } from '@/libs/userExperience'

/* Sorage for Roabook edition on map pages */
export const favoritePlaceStore = defineStore("favoriteplace", {
    state: () => ({
      places: <any>[],
      processing: <boolean> false
    }),
    actions: {
      async fetch() {
        this.processing = true;
        try {
          this.places = await favoriteAPI.all();
        }
        catch (error) {
          this.errorMessage('Favorites fetch error');
        }
        this.processing = false;
        return this.places;
      },

      init(){
        this.$reset();
        this.fetch();
      },

      async add(data:any){
          this.processing = true;
          let place = await favoriteAPI.create(data);
          await this.fetch();
          return place;
      },

      async remove(latLng:Array<number>){
        this.processing = true;
        await favoriteAPI.delete(`${latLng[0]},${latLng[1]}`);
        await this.fetch();
    },
      
      async errorMessage(message:string, type:string='danger'){
        const i18n = useI18n();
        presentToast(i18n.t(message), type)
      },

      isFavoritePlace(latLng:Array<number>){
        for(let place of this.places){
            if(place.coordinate[0] == latLng[0] && place.coordinate[1] == latLng[1]){
                return true;
            }
        }
        return false;
      }
    },
})