<template>
    <ion-list>
        <ion-item lines="none" button @click="setStart">
            <ion-icon slot="start" src="/start.svg"></ion-icon>
            <ion-label>{{ roadbookStore.value.map_waypoints.length >=1 ? $t('Set start'):$t('Start location')}}</ion-label>
        </ion-item>
        <ion-item v-if="roadbookStore.value.map_waypoints.length >= 1 && !roadbookStore.isRoundTrip()" lines="none" button @click="setDestination">
                <ion-icon slot="start" src="/stop.svg"></ion-icon>
                <ion-label>{{ roadbookStore.isRoutable() ? $t('Set destination'):$t('Destination location')}}</ion-label>
        </ion-item>
        
        <ion-item v-if="roadbookStore.isRoutable()" lines="none" button @click="addWaypoint">
            <ion-icon slot="start" :icon="flagSharp" color="tertiary"></ion-icon>
            {{$t('Add waypoint')}}
        </ion-item>

        <!-- <ion-row v-if="roadbookStore.isRoutable()">
            <ion-col size="8">
                <ion-item lines="none" button @click="addWaypoint">
                    <ion-icon slot="start" :icon="flagSharp" color="tertiary"></ion-icon>
                    {{$t('Waypoint')}}
                </ion-item>
            </ion-col>
            <ion-col size="4">
                <ion-input :value="wpNumber" ref="wpNumberInput" type="text" inputmode="numeric" class="step-number-input" 
                :min="1" :minlength="1" :max="maxWpNumber()" maxlength="3" :placeholder="defaultWpNumber()" @keyup.enter="addWaypoint"
                @ionChange="correctStepNumber" @ionInput="correctStepNumber"></ion-input>
            </ion-col>
           
        </ion-row> 
        -->
    </ion-list>

</template>

<script setup lang="ts">
    import { roadbookEditStore } from "@/stores/RoadbookEditStore";
    import { flagSharp } from "ionicons/icons";
    import { inject, ref } from "vue";
    import { maxWaypoints } from "@/libs/geoUtils";
    import { MapStore } from '@/stores/mapStore';

    // HTML components refs
    const wpNumberInput = ref();
    // Variable refs
    const wpNumber:any = ref(null)
    const roadbookStore = roadbookEditStore();
    const mapStore = MapStore();
    const { coordinates, dismiss, stepNumber, popoverType }:any = inject('popoverActions') 

    defineExpose({
        update,
    });

    function update(){
        // reset input value
        wpNumber.value = null;
        if(wpNumberInput.value) wpNumberInput.value.$el.value = null;
    }

    function dismissContextMenu(){
        if(popoverType.value.isPlace) mapStore.resetFocus();
        dismiss()
    }

    function setStart(){
        dismissContextMenu();
        roadbookStore.setStart(coordinates())
    }
    
    function setDestination(){
        dismissContextMenu();
        roadbookStore.setDestination(coordinates())
    }


    const defaultWpNumber = ()=>{
        if(stepNumber != null && stepNumber != undefined) return stepNumber;
        else return maxWpNumber()
    }
    const maxWpNumber = ()=>{
        return roadbookStore.value.map_waypoints.length - 1;
    }

    function addWaypoint(){
        dismissContextMenu();
        let waypointNumber = wpNumber.value 
        if(!waypointNumber || waypointNumber > maxWpNumber()) waypointNumber = maxWpNumber();
        else if(waypointNumber < 1) waypointNumber = 1;

        if(waypointNumber == maxWpNumber()) roadbookStore.addStep(coordinates());
        else roadbookStore.insertWaypoint(coordinates(), waypointNumber);
    }

    /* 
        Never go more than defaultWpNumber()
        Never go less than 1
        Never input alpha characters
    */
    function correctStepNumber(e:any){
        let value = e.detail.value;
        let number = Number(e.detail.value);
        if(value == '') wpNumber.value = null;
        else if(isNaN(number)) {/* ignore alpha */}
        else if(number > maxWpNumber()) wpNumber.value = maxWpNumber();
        else if(number > maxWaypoints) wpNumber.value = maxWaypoints;
        else if(number <= 1) wpNumber.value = 1;
        else wpNumber.value = value;
        wpNumberInput.value.$el.value = wpNumber.value; // update input value
    }
</script>

<style scoped>
</style>