<template>
    <ion-buttons v-if="buttons" :slot="slot">
        <ion-button v-if-desktop @click="open()" class="btn-icon-md">
            <ion-icon slot="icon-only" :icon="helpCircleOutline"></ion-icon>
        </ion-button>
    </ion-buttons>
    <ion-modal ref="helpModal" v-on:keyup.escape="dismiss" :can-dismiss="true">
        <ion-header>
            <ion-toolbar>
                <ion-icon slot="start" size="large" :icon="helpCircleOutline" class="ion-margin-start"></ion-icon>
                <ion-title>
                    {{ $t('Help') }}
                </ion-title>
                <ion-buttons slot="end">
                    <ion-button @click="dismiss" size="large">
                        <ion-icon slot="end" :icon="close"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content>
            <ion-list class="ion-no-padding">
                <ion-item v-for="shortcut in shortcuts" lines="none">
                    <ion-chip color="primary" outline="true" class="ion-margin-end">
                        <ion-label>{{shortcut.keys}}</ion-label>
                    </ion-chip>
                    <ion-label>{{ $t(shortcut.label) }}</ion-label>
                </ion-item>             
            </ion-list>
        </ion-content>
    </ion-modal>
</template>

<script setup lang="ts">
    import { close, helpCircleOutline } from 'ionicons/icons';
    import { ref } from 'vue';

    // HTML Element refs
    const helpModal = ref();
    // Variable refs
    const shortcuts = ref([
        {'keys':'ctrl + Z', 'label':'Undo'},
        {'keys':'ctrl + Y', 'label':'Redo'},
        {'keys':'ctrl + S', 'label':'Save'},
        {'keys':'ctrl + F', 'label':'Search'},
    ]);
    const height = ref((shortcuts.value.length * 48 + 75) + 'px'); // shortcuts length * ion-item height + ion-header height

    defineProps({
        buttons: {
            type: Boolean,
            default: true
        },
        slot: {
            type: String,
            default: 'end'
        }
    });

    const emit = defineEmits<{
        (e: 'onDismiss'): void
    }>();

    defineExpose({
        dismiss,
        open,
    });
    

    function open(){
        helpModal.value.$el.present();
    }

    function dismiss(){
        helpModal.value.$el.dismiss();
        emit('onDismiss');
    }

</script>

<style scoped>
ion-modal {
    --height: v-bind(height);
    --max-height: v-bind(height);
}
</style>