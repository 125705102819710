<template>
    <ion-modal ref="createModal" v-on:keyup.escape="dismiss" v-on:keyup.enter="validate" :can-dismiss="true">
        <ion-header>
            <ion-toolbar :color="verified?'success':''">
                <ion-title class="ion-margin-start">
                    {{$t('Add a Tripy device')}}
                </ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content>
            <ion-row>
                <ion-col size="12">
                    <ion-input ref="deviceSerialNumberInput" v-model="deviceSerialNumber" fill="outline" :disabled="verified" :label="$t('Serial Number')" type="text" placeholder="XXXXXXXXXX"></ion-input>
                </ion-col>
                <ion-col size="12">
                    <ion-button @click="validate" expand="block" shape="round" class="bold" size="large" :disabled="!deviceSerialNumber.length || verified">
                        <ion-icon v-if="verified" slot="start" :icon="checkmarkCircleOutline" color="success"></ion-icon>
                        {{$t('Verify')}}
                    </ion-button>
                </ion-col>
            </ion-row>
        </ion-content>
        <ion-toolbar class="ion-padding-end" :color="verified?'success':''">
        </ion-toolbar>
    </ion-modal>
</template>

<script setup lang="ts">
    import { ref } from 'vue';
    import { focusInput } from '@/libs/userExperience.js'
    import { deviceAPI } from '@/libs/backend';
    import { checkmarkCircleOutline } from 'ionicons/icons';

    // Component event emission
    const emit = defineEmits<{
        (e: 'dismiss'): void
        (e: 'created', data:any): void
    }>();

    defineExpose({
        dismiss,
        open,
    });

    // HTML Element refs
    const createModal = ref();
    const deviceSerialNumberInput = ref();

    // Variable refs
    const deviceSerialNumber = ref('');
    const verified = ref(false);

    function resetFields(){
        verified.value = false;
        deviceSerialNumber.value = '';
    }

    function validate(){
        deviceAPI.validate(deviceSerialNumber.value).then((response)=>{
            verified.value = response.success

            if(verified.value == true){
                setTimeout(() => {
                    create()
                }, 1000);
            }
        });
    }

    function create(){
        let data:any = {};
        data.serial_number = deviceSerialNumber.value
        
        deviceAPI.create(data).then((res)=>{
            emit('created', res);
            dismiss(); 
        })
    }

    async function open(){
        resetFields();
        createModal.value.$el.present();
        focusInput(deviceSerialNumberInput)
    }

    function dismiss(){
        createModal.value.$el.dismiss();
        emit('dismiss');
    }

</script>

<style scoped>

ion-modal {
    --height: 300px;
    --max-height: 300px;
    --max-width: 350px;
}

</style>