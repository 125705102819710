<template>
    <ion-modal ref="createModal" v-on:keyup.escape="dismiss" v-on:keyup.enter="submit" :can-dismiss="true">
        <ion-header>
            <ion-toolbar>
                <ion-title class="ion-margin-start">
                    {{$t('Add to favorites')}}
                </ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content>
            <ion-row>
                <ion-col size="12">
                    <ion-input ref="placeLabelInput" v-model="placeLabel" fill="outline" :label="$t('Label')" type="text"></ion-input>
                </ion-col>
                <ion-col size="12">
                    <ion-input v-model="placeIcon" fill="outline" :label="$t('Icon')" type="text"></ion-input>
                </ion-col>
            </ion-row>
        </ion-content>
        <ion-toolbar class="ion-padding-end">
            <ion-button slot="end" class="" color="light" expand="full" @click="dismiss" fill="clear">{{$t('Discard')}}</ion-button>
            <ion-button slot="end" class="bold" color="light" expand="full" @click="submit($event)" fill="outline" :disabled="!placeLabel">{{$t('OK')}}</ion-button>
        </ion-toolbar>
    </ion-modal>
</template>

<script setup lang="ts">
    import { ref } from 'vue';
    import { focusInput } from '@/libs/userExperience.js'
    import { favoritePlaceStore } from '@/stores/FavoritePlaceStore'

    // Component event emission
    const emit = defineEmits<{
        (e: 'dismiss'): void
        (e: 'created', data:any): void
    }>();

    defineExpose({
        dismiss,
        open,
    });

    // Store
    const favoritePlaces = favoritePlaceStore();

    // HTML Element refs
    const createModal = ref();
    const placeLabelInput = ref();

    // Variable refs
    const placeLabel = ref('');
    const placeIcon = ref('');
    const coordinate:any = ref(undefined)

    async function submit(e:Event){
        e.stopPropagation();
        let data:any = {
            coordinate: coordinate.value,
            label: placeLabel.value,
            icon: placeIcon.value
        };
        
        let res = await favoritePlaces.add(data);

        emit('created', res);
        dismiss(); 
    }

    function resetFields(){
        placeLabel.value = '';
        placeIcon.value = '';
    }

    async function open(profiledCoordinate:any){
        coordinate.value = [profiledCoordinate[0], profiledCoordinate[1]]
        resetFields();
        createModal.value.$el.present();
        focusInput(placeLabelInput)
    }

    function dismiss(){
        createModal.value.$el.dismiss();
        emit('dismiss');
    }

</script>

<style scoped>

ion-modal {
    --height: 250px;
    --max-height: 250px;
    --max-width: 350px;
}

</style>