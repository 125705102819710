<template>
    <div>
        <ion-button @click="displayVideo = !displayVideo" fill="clear" shape="round" color="medium" size="medium" :class="props.class+' ion-margin-start'">
            <ion-icon v-if="!displayVideo" slot="start" :icon="playCircleOutline"></ion-icon>
            <ion-icon v-else slot="start" :icon="close"></ion-icon>
            {{$t('Tuto')}}
        </ion-button>
        <iframe v-if="displayVideo" width="400" height="315" src="https://www.youtube.com/embed/uRFbaQi6U_4?si=VydKIzgo4HyQ6zXt" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    </div>
</template>

<script setup lang="ts">
    import { ref } from 'vue'
    import { close, playCircleOutline } from 'ionicons/icons';

    const props = defineProps({
        class: {
            type: String,
            default: '',
        },
    });

    const displayVideo = ref(false);
</script>