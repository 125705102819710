<template>
    <ion-row>
        <ion-col size="12">
            <ion-input ref="passwordInput" type="password" :placeholder="$t('password')" v-model="password" @ionInput="clear(); $emit('update:password', $event.target.value)" :error-text="passwordError" class="signup-input">
                <passwordMeter v-show="false" :password="password" @score="onPasswordChange" />
                <ion-chip v-show="passwordStrength" :color="passwordSafeColor" slot="end" >
                    <ion-label>{{$t(passwordStrength)}}</ion-label>
                </ion-chip>
                <ion-input-password-toggle slot="end"></ion-input-password-toggle>
            </ion-input>
        </ion-col>
        <ion-col size="12">
            <ion-input ref="passwordRepeatInput" type="password" :placeholder="$t('confirm password')" v-model="passwordRepeat" @ionInput="clear" :error-text="$t('Passwords are different')" class="signup-input">
                <ion-input-password-toggle slot="end"></ion-input-password-toggle>
            </ion-input>
        </ion-col>
    </ion-row>
</template>

<script setup lang="ts">
    import { showIonInputError, clearIonInputError } from '@/libs/userExperience';
    import { ref } from 'vue';
    import passwordMeter from "vue-simple-password-meter";
    import { useI18n } from 'vue-i18n';

    const i18n = useI18n()
    const password = ref('')
    const passwordError = ref('');
    const passwordRepeat = ref('');
    const passwordScore = ref(0);
    const passwordStrength = ref('')
    const passwordSafeColor = ref('danger')

    // HTML elements refs
    const passwordInput = ref();
    const passwordRepeatInput = ref();


    // defineProps(['password'])
    defineEmits(['update:password'])


    defineExpose({
        hasError
    })

    function clear(){
        clearIonInputError(passwordInput)
        clearIonInputError(passwordRepeatInput)
    }

    function hasError(){

        showIonInputError(passwordInput, false)
        showIonInputError(passwordRepeatInput, false)

        let emptyError = showIonInputError(passwordInput, !password.value);
        if(emptyError) {
            passwordError.value = i18n.t('Required field')
            return true;
        }

        let diffError = showIonInputError(passwordRepeatInput, password.value != passwordRepeat.value);
        if(diffError){
            passwordError.value = i18n.t('Passwords are different')
            return true;
        }
    
        let scoreError = showIonInputError(passwordInput, passwordScore.value < 4); // score from 0 to 4 (https://miladd3.github.io/vue-simple-password-meter/)
        if(scoreError){
            passwordError.value = i18n.t('Password is too weak')
            return true;
        } 

        return false
    }

    function onPasswordChange(passwordMeter:any){
        passwordScore.value = passwordMeter.score;
        passwordStrength.value = passwordMeter.strength;
        if(passwordMeter.strength == 'risky'){
            passwordSafeColor.value = 'danger';
        }
        else if(passwordMeter.strength == 'guessable'){
            passwordSafeColor.value = 'warning';
        }
        else if(passwordMeter.strength == 'weak'){
            passwordSafeColor.value = 'primary';
        }
        else if(passwordMeter.strength == 'safe'){
            passwordSafeColor.value = 'success';
        }
        else if(passwordMeter.strength == 'secure'){
            passwordSafeColor.value = 'success';
        }
    }
</script>

<style scoped>
    @import '../theme/login.css';
</style>