<template>
    <ion-grid>
        <ion-row>
            <ion-col v-for="roadbook in roadbooks" :size="isDesktop?cardSize:12">
                <ion-card @click="select($event, roadbook)" :class="{selectable: (selectionEnabled == true), selected:(isSelected(roadbook))}" button>
                    <ion-item lines="none">
                        <ion-icon v-if="selectionEnabled && isSelected(roadbook)" slot="start" :icon="checkboxOutline" color="tertiary"></ion-icon>
                        <ion-icon v-else-if="selectionEnabled && !isSelected(roadbook)" slot="start" :icon="squareOutline" color="light"></ion-icon>
                        <ion-label>
                            {{ roadbook.title }}
                            <ion-chip color="primary" mode="ios" class="ion-no-margin">
                                <ion-label>{{$t(roadbook.type)}}</ion-label>
                            </ion-chip>
                            <ion-chip v-if="roadbook.roundtrip" color="secondary" mode="ios">
                                <ion-label>{{$t('roundtrip')}}</ion-label>
                            </ion-chip>
                        </ion-label>
                        <ion-buttons slot="end">
                            <ion-button :id="'roadbook-'+roadbook.id">
                                <ion-icon slot="icon-only" :icon="ellipsisVertical"></ion-icon>
                            </ion-button>
                        </ion-buttons>
                    </ion-item>
                    <ion-card-content @click="isMobile && revealCard(roadbook.id)" @mouseover="revealCard(roadbook.id)" @mouseleave="revealCard(null)" class="ion-no-padding">
                        <ion-icon v-if="roadbook.favorite" :icon="heart" color="tertiary" class="favortie"></ion-icon>
                        <div v-show="!selectionEnabled && revealCardActions == roadbook.id" class="actions-overlay">
                            <ion-button expand="block" shape="round" @click="roadbookInfoModal.open(roadbook.id)">
                                <ion-icon slot="start" :icon="informationCircleOutline"></ion-icon>
                                <ion-label class="bold">{{$t('Details')}}</ion-label>
                            </ion-button>
                            <ion-button expand="block" shape="round" @click="editRoadook(roadbook)">
                                <ion-icon slot="start" :icon="createOutline"></ion-icon>
                                <ion-label class="bold">{{$t('Edit')}}</ion-label>
                            </ion-button>
                        </div>
                        <img src="/screenshot.png"/>
                    </ion-card-content>
                    <ion-popover :trigger="'roadbook-'+roadbook.id" :dismiss-on-select="true">
                        <ion-content>
                            <ion-list>
                                <ion-item button :detail="false" @click="toggleFavorite(roadbook)">
                                    <ion-icon slot="start" :icon="roadbook.favorite ? heartDislike:heart"></ion-icon>
                                    {{ $t('Favorite') }}
                                </ion-item>
                                <ion-item button :detail="false" @click="rename(roadbook)">
                                    <ion-icon slot="start" :icon="createOutline"></ion-icon>
                                    {{ $t('Rename') }}
                                </ion-item>
                                <ion-item button :detail="false" @click="copyRoadbook(roadbook)">
                                    <ion-icon slot="start" :icon="copyOutline"></ion-icon>
                                    {{ $t('Copy') }}
                                </ion-item>
                                <ion-item-divider color="medium"></ion-item-divider>
                                <ion-item button :detail="false" @click="shareRoadbook(roadbook)">
                                    <ion-icon slot="start" :icon="arrowRedoOutline"></ion-icon>
                                    {{ $t('Share') }}
                                </ion-item>
                                <ion-item button :detail="false" @click="uploadRoadbook(roadbook)">
                                    <ion-icon slot="start" :icon="cloudUpload" color="tertiary"></ion-icon>
                                    {{ $t('Add to Tripy device') }}
                                </ion-item>
                                <ion-item-divider color="medium"></ion-item-divider>
                                <ion-item button :detail="false" @click="archiveRoadbook(roadbook)">
                                    <ion-icon slot="start" :icon="archive"></ion-icon>
                                    {{ $t('Archive') }}
                                </ion-item>
                                <ion-item button :detail="false" @click="deleteRoadbook(roadbook.id)">
                                    <ion-icon slot="start" :icon="trashBinOutline" color="danger"></ion-icon>
                                    {{ $t('Delete') }}
                                </ion-item>
                            </ion-list>
                        </ion-content>
                    </ion-popover>
                </ion-card>
            </ion-col>
        </ion-row>
    </ion-grid>
</template>

<script setup lang="ts">
    import { roadbookAPI } from '@/libs/backend';
    import { archive, arrowRedoOutline, checkboxOutline, cloudUpload, copyOutline, createOutline, ellipsisVertical, heart, heartDislike, informationCircleOutline, squareOutline, trashBinOutline } from 'ionicons/icons';
    import { ref, onMounted, onUpdated, inject } from 'vue';
    import { refreshData, confirmAlert } from '@/libs/userExperience'
    import { useI18n } from "vue-i18n";
    import { useRouter } from 'vue-router';
    import { createAnimation, loadingController, alertController } from '@ionic/vue';
    import { confirmDeletion } from '@/libs/userExperience'

    // Variables
    const isDesktop=inject('isDesktop')
    const isMobile=inject('isMobile')
    const i18n = useI18n();
    const router = useRouter();
    
    const roadbooks:any = ref([])
    const fetchedRoadbooks:any = ref([])
    const selectionEnabled = ref(false)
    const revealCardActions = ref(null)
    const cardSize = ref(2.4)
    
    var copied = false;
    var selectedRoadbooks:any = ref([]);

    // HTML Element refs
    const roadbookModal = ref()
    const createButton = ref()
    const roadbookFilter = ref()
    const roadbookInfoModal = ref()

    onMounted(async ()=>{
        fetchRoadbooks();

        // Create button animation
        createAnimation()
          .addElement(createButton.value.$el)
          .fill('none')
          .duration(5000)
          .iterations(Infinity)
          .keyframes([
            { offset: 0, transform: 'scale(1)', opacity: '1' },
            { offset: 0.1, transform: 'scale(1.2)', opacity: '0.3' },
            { offset: 0.2, transform: 'scale(1)', opacity: '1' },
            { offset: 1, transform: 'scale(1)', opacity: '1' },
          ]).play();
    });

    onUpdated(async ()=>{
        refreshData(fetchRoadbooks)
    });

    function toggleCardSize(){
        console.log(cardSize.value)
        cardSize.value = 4
        if(cardSize.value == 2.4) cardSize.value = 3
        if(cardSize.value == 3) cardSize.value = 4
        if(cardSize.value == 4) cardSize.value = 2.4
    }
  
    function revealCard(roadbookId:any){
        if(isMobile && revealCardActions.value == roadbookId) revealCardActions.value = null
        else revealCardActions.value = roadbookId;
    }

    async function fetchRoadbooks(){
        selectedRoadbooks.value = []; // reset selected roadbooks
        selectionEnabled.value = false;
        roadbooks.value = await roadbookAPI.all();
        fetchedRoadbooks.value = roadbooks.value;
    }

    async function roadbookCreated(roadbook:any){
        let edit = true;
        if(copied){
            fetchRoadbooks(); // display new one anyway
            edit = await confirmAlert({
                header: i18n.t('Edit this copy ?'),
                mode: 'ios',
                buttons: [
                    { text: i18n.t('No'), role: 'cancel' },
                    { text: i18n.t('Yes'), role: 'confirm' },
                ]
            })
        }

        copied = false;
        
        if(edit){
            router.push({path: `/roadbooks/${roadbook.id}/edit/${roadbook.type}`})
        }
    }

    async function rename(roadbook:any){
        const alert = await alertController.create({
            header: i18n.t('Edit Title'),
            buttons: [ 
                {role:"cancel", text: i18n.t('Discard')},
                {role:"confirm", text: i18n.t('OK')}
            ],
            inputs: [
                {
                    placeholder: i18n.t('Add a title here'),
                    value: roadbook.title,
                    id: 'title-rename',
                },
            ],
        });
        await alert.present();
        // focus field on alert show
        document.getElementById('title-rename')?.focus();

        const {role, data} = await alert.onDidDismiss();
        if(role == 'confirm'){ 
            // update roadbook title
            roadbook.title = data.values[0];
            roadbookAPI.update(roadbook.id, roadbook);
        }
    }

    function shareRoadbook(roadbook:any){
        alert('TODO SHARE')
    }

    function uploadRoadbook(roadbook:any){
        alert('TODO UPLOAD')
    }

    function archiveRoadbook(roadbook:any){
        alert('TODO ARCHIVE')
    }

    async function deleteRoadbook(roadbookID:string){
        if(await confirmDeletion()){
            roadbookAPI.delete(roadbookID).then(()=> fetchRoadbooks())
        }
    }

    function copyRoadbook(roadbook:any){
        let data = JSON.parse(JSON.stringify(roadbook)); // copy object
        data.title = i18n.t('Copy of') + " " + data.title
        if(data.id) delete data.id
        copied = true;
        roadbookModal.value.openCopy(data)
    }

    function sitchToSelect(){
        selectionEnabled.value = !selectionEnabled.value;
        selectedRoadbooks.value = [];
    }

    function select(e:Event, roadbook:any){
        if(selectionEnabled.value == true){
            e.preventDefault()
            e.stopPropagation()
            let index = selectedRoadbooks.value.indexOf(roadbook.id);
            if(index == -1) selectedRoadbooks.value.push(roadbook.id)
            else{
                selectedRoadbooks.value.splice(index,1)
            }
        }
    }

    function editRoadook(roadbook:any){
        if(selectionEnabled.value == true){

        }
        else{
            router.push({path: `/roadbooks/${roadbook.id}/edit/${roadbook.type}`})
        }
    }

    function isSelected(roadbook:any){
        return (selectedRoadbooks.value.indexOf(roadbook.id) != -1)
    }

    async function tagSelected(){
        let actionLoader = await loadingController.create({
            message: i18n.t('Processing'),
        });
        actionLoader.present()
        for(let roadbookID of selectedRoadbooks.value){
            // await roadbookAPI.delete(roadbookID)
        }
        await fetchRoadbooks();
        actionLoader.dismiss()
    }

    async function archiveSelected(){
        let actionLoader = await loadingController.create({
            message: i18n.t('Processing'),
        });
        actionLoader.present()
        for(let roadbookID of selectedRoadbooks.value){
            await roadbookAPI.update(roadbookID, {'archived': true})
        }
        fetchRoadbooks();
        actionLoader.dismiss()
    }

    async function deleteSelected(){
        let actionLoader = await loadingController.create({
            message: i18n.t('Processing'),
        });
        actionLoader.present()
        for(let roadbookID of selectedRoadbooks.value){
            await roadbookAPI.delete(roadbookID)
        }
        await fetchRoadbooks();
        actionLoader.dismiss()
    }

    function toggleFavorite(roadbook:any){
        roadbook.favorite = !roadbook.favorite;
        let data = {favorite: roadbook.favorite};
        roadbookAPI.update(roadbook.id, data).then((res)=>{
            roadbook.favorite = res.favorite;
        });
    }
</script>

<style scoped>
    ion-card {
        border-radius: 10px;
    }
    .selectable{
        border: 1px solid white;
    }
    .selected{
        border: 1px solid var(--ion-color-tertiary) !important;
    }
    .favortie {
        z-index: 200;
        position: absolute;
        font-size: 50px;
        background-color: var(--ion-component-background);
        bottom: 0px;
        right: 0px;
        border-radius: 6px;
    }
    .actions-overlay {
        z-index: 100;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(150,150,150,0.5);
    }
    .actions-overlay ion-button {
        height: 60px;
        font-size: 20px
    }
    .actions-overlay ion-button:first-child {
        margin-top: 35%;
    }
    ion-item-divider {
    height: 5px !important;
    max-height: 5px !important;
    min-height: 5px !important;
    }
</style>