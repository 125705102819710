<template>
    <ion-modal ref="modal" mode="ios">
        <ion-content>
            <div v-if="isLoggedIn()">
                <ion-text color="primary" class="ion-text-center"><h2>{{ $t('Upload to my Tripy') }}</h2></ion-text>
                <ion-list v-if="devices.length">
                    <ion-item v-for="device of devices" button mode="md" @click="selectDevice(device)">
                        <ion-icon slot="start" :icon="device.selected ? checkmarkCircle:squareOutline" :color="device.selected ? 'success':''"></ion-icon>
                        <ion-label>{{ device.label }}</ion-label>
                    </ion-item>
                </ion-list>
                <div v-else class="ion-text-center ion-padding">
                    <ion-button expand="block" shape="rounded" fill="outline" class="ion-margin">
                        <ion-icon slot="start" :icon="addOutline"></ion-icon>
                        {{ $t('Register your Tripy GPS') }}
                    </ion-button>
                    <ion-button expand="block" shape="rounded" fill="outline" class="ion-margin">
                        <ion-icon slot="start" :icon="cartOutline"></ion-icon>
                        {{ $t('Buy a Tripy GPS') }}
                    </ion-button>
                    <ion-item  button>
                        <ion-label>
                            {{ $t('What is a Tripy GPS ?!') }}
                        </ion-label>
                    </ion-item>
                </div>
            </div>
            <div v-else class="ion-margin-top">
                <ion-row>
                    <ion-col size="12">
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col size="12">
                    <h3 class="ion-text-center">{{$t('You are not logged in')}}</h3>
                    <ion-button @click="exportGPX" expand="block" shape="rounded">
                        {{ $t('Login') }}
                    </ion-button>
                    <ion-button @click="exportGPX" expand="block" shape="rounded" fill="clear">
                        {{ $t('Account creation') }}
                    </ion-button>
                    </ion-col>
                </ion-row>
            </div>
        </ion-content>
        <ion-footer>
            <ion-toolbar>
                <ion-row>
                    <ion-col :size="devices.length ? 6:12">
                        <ion-button @click="exportGPX" expand="block" shape="rounded"  fill="outline" color="light">
                            {{ $t('Export GPX') }}
                        </ion-button>
                    </ion-col>
                    <ion-col v-if="isLoggedIn() && devices.length" size="6">
                        <ion-button @click="exportGPX" expand="block" shape="rounded" fill="outline" :disabled="!selectedDevicesCount">
                            <ion-icon slot="start" :icon="cloudUpload"></ion-icon>
                            {{ $t('Upload') }}
                        </ion-button>
                    </ion-col>
                </ion-row>

             </ion-toolbar>
        </ion-footer>
    </ion-modal>
</template>

<script setup lang="ts">
    import { ref, onMounted } from 'vue';
    import { checkmarkCircle, cartOutline,  addOutline, cloudUpload, squareOutline } from 'ionicons/icons';
    import { isLoggedIn } from '@/services/authService';
    import { deviceAPI } from '@/libs/backend';


    // variables refs
    const devices:any = ref([])
    const selectedDevicesCount:any = ref(0)

    // HTML components refs
    const modal = ref();
    
    defineExpose({
        present,
        close,
    });

    onMounted(async()=>{
        devices.value = await deviceAPI.all();

        // select by default first one if only one device
        if(devices.value.length == 1) {
            devices.value[0].selected = true
            selectedDevicesCount.value += 1
        }
    });

    function present(){
        modal.value.$el.present()
    }

    function close(){
        modal.value.$el.dismiss()
    }

    function selectDevice(device:any){
        if(device.selected == undefined) device.selected = true;
        else device.selected = ! device.selected;

        if(device.selected) selectedDevicesCount.value += 1
        else selectedDevicesCount.value -= 1
    }

    function exportGPX(){

    }


</script>

<style scoped>
    ion-modal {
        --height: 55%;
        --border-radius: 16px;
        --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    }

    .modal-section {
        font-size: 20px;
    }

    .search-card {
        margin: 0 0 20px 0;
    }

    .toolbar-title {
        text-overflow: unset !important;
    }

    ion-chip.chip-outline {
        background-color: var(--ion-color-success) !important;
        color: white
    }

    ion-content ion-card {
        box-shadow: none;
    }

    ion-segment { 
        width: 100%;
    }
</style>