<template>
    <ion-list>
        <ion-item v-if="roadbookStore.isRoutable()" lines="none" button 
            @click="roadbookStore.switchRoundTrip();dismiss($event);">
                <ion-icon slot="start" :src="roadbookStore.isRoundTrip()? 'reload-stroke.svg':'reload.svg'"></ion-icon>
                <ion-label>{{ roadbookStore.isRoundTrip()? $t('Without roundtrip'):$t('roundtrip')}}</ion-label>
        </ion-item>
        <ion-item v-if="roadbookStore.isRoundTrip() && roadbookStore.isSectionDivisibleBefore(coordinates())" lines="none" button
            @click="roadbookStore.addWaypointBefore(coordinates());dismiss($event);">
            <ion-icon slot="start" src="/step-insert-before.svg"></ion-icon>
            <ion-label>{{ $t('Insert step before')}}</ion-label>
        </ion-item>
        <ion-item v-if="roadbookStore.isRoutable() && roadbookStore.isSectionDivisibleAfter(coordinates())" lines="none" button
            @click="roadbookStore.addWaypointAfter(coordinates());dismiss($event);">
            <ion-icon slot="start" src="/step-insert-after.svg"></ion-icon>
            <ion-label>{{ $t('Insert step after')}}</ion-label>
        </ion-item>
        <ion-item v-if="!roadbookStore.isRoundTrip() && roadbookStore.isRoutable()" lines="none" button 
            @click="roadbookStore.switchStartDest();dismiss($event)">
                <ion-icon slot="start" src="swap-start-dest.svg"></ion-icon>
                <ion-label>{{ $t('Swap with destination') }}</ion-label>
        </ion-item>
    </ion-list>
</template>

<script setup lang="ts">
    import { roadbookEditStore } from "@/stores/RoadbookEditStore";
    import { inject } from "vue";

    const roadbookStore = roadbookEditStore();
    const { dismiss, coordinates }:any = inject('popoverActions') 
</script>

<style scoped>
</style>